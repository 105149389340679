import React from 'react';
import GameSkeleton from '@components/Game/GameSkeleton';

const SkeletonGrid = ({ items }) => (
  <div className="GridContainer">
    <div className="grid-header--skeleton shine" />
    <div className="GameGrid GameGrid--extra-margin-on-mobile">
      <div className="games grid">
        {[...Array(items)].map((_, i) => (
          <GameSkeleton key={`grid-${i}`} />
        ))}
      </div>
    </div>
  </div>
);

export default SkeletonGrid;
