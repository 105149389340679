import { ofType, combineEpics } from 'redux-observable';
import { map } from 'rxjs/operators';
import Cookies from 'universal-cookie';
import { fetchGames } from '../actions/games';

const fetchGamesAfterAPIBootstrapCompleted = action$ =>
  action$.pipe(
    ofType('API_BOOTSTRAP_COMPLETE'),
    map(() => fetchGames('en-US', null, new Cookies()))
  );

export default combineEpics(fetchGamesAfterAPIBootstrapCompleted);
