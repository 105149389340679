import React, { useState, useEffect, Fragment } from 'react';
import { createImageUrl, FORMAT } from '@utils/picture';
import marked from '@utils/markdownParser';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, Translate, Input } from 'tg-core-components';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import { validateRules, required } from '../../validation';
import translate from 'tg-core-components/lib/lib/utils/translate';
import selectAvailableBonuses from '@selectors/selectAvailableBonuses';
import { getContinueLabel } from '../../helpers';
import { Link } from 'react-router-dom';
import Loader from '@components/Loader';
import { openHash } from '@utils/hash';

import './style.css';

const EnterPromoCode = ({
  isValid,
  fields,
  nextStep,
  errors,
  intl,
  onChange,
  setShowEnterPromoCode,
  showEnterPromoCode,
  device,
  statuses,
}) => (
  <>
    <Input
      type="checkbox"
      label={translate(
        {
          id: 'label.bonus-code',
          defaultMessage: 'I have a bonus code',
        },
        intl
      )}
      value={showEnterPromoCode}
      onChange={(_, showEnterPromoCode) => {
        setShowEnterPromoCode(showEnterPromoCode);
      }}
    />

    {showEnterPromoCode && (
      <>
        <Input
          autoFocus
          type="text"
          label={translate(
            {
              id: 'label.enter-promo-code',
              defaultMessage: 'Enter your bonus code',
            },
            intl
          )}
          value={fields.PromoCode}
          name="Name"
          onChange={(_, PromoCode) => onChange({ PromoCode })}
          status={statuses.PromoCode}
          statusText={
            errors.PromoCode && translate({ id: errors.PromoCode }, intl)
          }
        />

        <Button
          onClick={nextStep}
          className={cn('continue primary', {
            'continue--valid': isValid,
          })}>
          {getContinueLabel(device)}
        </Button>
      </>
    )}
  </>
);

const Bonus = ({
  /* Props from parent */
  onChange,
  nextStep,
  fields,
  intl,
  errors,
  statuses,

  /* Props from selector */
  bonuses,
  device,
  sessionId,
}) => {
  const dispatch = useDispatch();
  const [noBonuses, setNoBonuses] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!sessionId) return;

    const promise = dispatch(bonusActions.getOffers(sessionId));

    promise.then(action => {
      if (action?.data?.length === 0) {
        setNoBonuses(true);
      }
      setIsLoading(false);
    });
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (noBonuses) {
      nextStep();
    }
  }, [noBonuses, nextStep]);

  const [showEnterPromoCode, setShowEnterPromoCode] = useState(false);

  const isValid = statuses.PromoCode === 'success';

  return (
    <div className="Bonus">
      {isLoading ? (
        <Loader inline />
      ) : (
        <Fragment>
          <EnterPromoCode
            showEnterPromoCode={showEnterPromoCode}
            setShowEnterPromoCode={setShowEnterPromoCode}
            errors={errors}
            fields={fields}
            nextStep={nextStep}
            onChange={onChange}
            intl={intl}
            isValid={isValid}
            device={device}
            statuses={statuses}
          />

          {!showEnterPromoCode && (
            <div className="BonusList">
              {bonuses.map((bonus, i) => {
                const selected = bonus.bonusCode === fields.PromoCode;

                if (!bonus.content) return;

                return (
                  <div
                    key={i}
                    className={cn('BonusList__item', {
                      'BonusList__item--selected': selected,
                    })}>
                    <div
                      className="BonusList__item__bonus"
                      style={{
                        backgroundImage: `url(${createImageUrl({
                          src: bonus?.image?.file?.url,
                          quality: 75,
                          progressive: true,
                          width:
                            __CLIENT__ && Math.min(1920, window.innerWidth),
                          format: FORMAT.JPG,
                        })})`,
                      }}>
                      <div className="BonusList__item__bonus__content">
                        {bonus?.summary && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marked(bonus?.summary || ''),
                            }}
                          />
                        )}

                        <Link
                          to={{
                            hash: openHash(
                              location.hash,
                              `#popup/terms-and-conditions/bonus/${bonus?.identifier}`
                            ),
                          }}>
                          <Translate
                            id="action.read-full-tac"
                            defaultMessage="READ FULL T&Cs"
                          />
                        </Link>
                      </div>

                      <Button
                        className="primary tiny"
                        onClick={() => {
                          onChange({ PromoCode: bonus?.bonusCode });
                          nextStep();
                        }}>
                        {selected ? (
                          <Translate
                            id="action.button.selected"
                            defaultMessage="Selected"
                          />
                        ) : (
                          <Translate
                            id="action.button.pick"
                            defaultMessage="Pick"
                          />
                        )}
                      </Button>
                    </div>

                    {bonus?.disclaimer && (
                      <div
                        className="BonusList__item__disclaimer"
                        dangerouslySetInnerHTML={{
                          __html: marked(bonus?.disclaimer || ''),
                        }}
                      />
                    )}
                  </div>
                );
              })}
              <div className="BonusList__item">
                <div className="BonusList__item__bonus">
                  <div className="BonusList__item__bonus__content">
                    <div>
                      <Translate
                        id="label.no-thanks"
                        defaultMessage="No thanks"
                      />
                    </div>
                    <div>
                      <Translate
                        id="label.continue-without-bonus"
                        defaultMessage="I will continue without a bonus"
                      />
                    </div>
                  </div>

                  <Button
                    className="primary tiny"
                    onClick={() => {
                      onChange({ PromoCode: null });
                      nextStep();
                    }}>
                    <Translate id="action.button.pick" defaultMessage="Pick" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

const validate = async ({ PromoCode }) => {
  const rules = {
    PromoCode: [[required, 'error.empty.promo-code']],
  };
  const errors = await validateRules({ PromoCode }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  bonuses: selectAvailableBonuses(state),
  device: state.app.device,
  sessionId: state.player.sessionId,
});

export default {
  name: 'bonus',
  Component: Bonus,
  validate,
  selector,
  fields: ['PromoCode'],
};
