// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'sga',
  countries: ['se'],
  tgplay: {
    skin: 'turbovegas_sga_pz',
  },
  metric: {
    operator: 'turbovegas-se',
    host:
      CURRENT_ENV === 'prod'
        ? 'https://turbovegas-se.betpump.com'
        : 'https://turbovegas-se.superlivepreprod.com',
  },
  chat: {
    provider: 'freshchat',
  },
  showQuickAmounts: true
});
