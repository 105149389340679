import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate } from 'tg-core-components';
import marked from 'marked';
import compose from 'recompose/compose';
import withHideOnServer from '@components/hoc/withHideOnServer';
import { useUrls } from '@utils/urls';
import { getLobbyUrl } from '@utils/game';
import MultipleVideos from '@components/MultipleVideos';
import withInViewport from '@components/hoc/withInViewport';
import cn from 'classnames';

import './style.css';

export const selectCasinoPromotionProps = state => ({
  isAuthenticated: state.player.isAuthenticated,
});

const CasinoPromotion = ({
  image,
  video,
  videos,
  backgroundImage,
  action,
  cta,
  game,
  config,
  content,
  isAuthenticated,
  location,
}) => {
  const { signIn } = useUrls();
  const imageUrl = image?.file.url;
  const allVideos = [video, ...(videos || [])]
    .filter(el => typeof el !== 'undefined')
    .map(video => video && video.file);
  const backgroundUrl = backgroundImage?.file.url;
  let pathname, search, hash;
  if (action?.startsWith('#')) {
    hash = action.slice(1);
  } else {
    [pathname, search] = action ? action.split('?') : ['', ''];
  }
  if (game?.isLive && !isAuthenticated) {
    const { pathname: newPathname, hash: newHash } = signIn();
    search = '';
    pathname = newPathname;
    hash = newHash;
  }

  const PlayLink = props => {
    if (props.action) {
      const [p, h] = props.action.split('#');
      pathname = p;
      hash = h;
    }
    return (
      <Link
        {...props}
        to={{
          pathname,
          search,
          hash,
          state: {
            backUrl: getLobbyUrl(game, location),
          },
        }}>
        {props.children}
      </Link>
    );
  };

  const renderVideo = () =>
    allVideos.length > 0 ? (
      <div className="casino-promotion-video">
        <PlayLink action={action}>
          <MultipleVideos
            videos={allVideos}
            muted
            loop
            autoPlay
            poster={imageUrl}
            playsInline
          />
        </PlayLink>
      </div>
    ) : null;

  const renderImage = () =>
    imageUrl ? (
      <PlayLink
        action={action}
        className="casino-promotion-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    ) : null;

  const renderAcions = () =>
    cta &&
    action && (
      <PlayLink className="actions button primary" action={action}>
        {cta}
      </PlayLink>
    );

  const renderContent = () => {
    const gameLogoUrl = config?.gameLogo;

    return (
      <div className="casino-promotion-content">
        {game && (
          <div className="stats-wrapper">
            <div className="stats">
              {(game.subprovider || game.provider) && (
                <span className="stats-provider">
                  {game.subprovider || game.provider}
                </span>
              )}
              {game.rtp && ' - '}
              {game.rtp && (
                <Translate
                  id="label.rtp"
                  defaultMessage="{value}% RTP"
                  values={{ value: game.rtp }}
                />
              )}
            </div>
            {gameLogoUrl && <img src={gameLogoUrl} alt={game.name} />}
          </div>
        )}

        {content && (
          <div
            className="summary"
            dangerouslySetInnerHTML={{ __html: marked(content) }}
          />
        )}

        {renderAcions()}
      </div>
    );
  };

  return (
    <section className="casino-promotion">
      <div
        className={cn('casino-promotion-bg', {
          'casino-promotion-bg--has-background': backgroundUrl,
        })}
        style={{
          ...(backgroundUrl && {
            backgroundImage: `url(${backgroundUrl})`,
          }),
        }}
      />
      <div className="container-max-width content-image-wrapper">
        {allVideos.length > 0 ? renderVideo() : renderImage()}
        {renderContent()}
      </div>
    </section>
  );
};

const shouldComponentUpdate = (prevProps, nextProps) => {
  return prevProps.inViewPort !== nextProps.inViewPort;
};

export default compose(
  withRouter,
  withHideOnServer,
  withInViewport
)(React.memo(CasinoPromotion, shouldComponentUpdate));
