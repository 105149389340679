import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate, Button } from 'tg-core-components';
import cn from 'classnames';
import format from 'date-fns/format';
import LiveCasinoInfo, { selectLiveCasinoInfoProps } from './LiveCasinoInfo';
import { CATEGORY_TYPES } from '@utils/casino';
import { getLobbyUrl, isFavorite } from '@utils/game';
import { useUrls } from '@utils/urls';
import clock from '../../images/clock.svg';
import selectFavoriteGames from '@selectors/selectFavoriteGames';
import selectLastPlayedGames from '@selectors/selectLastPlayedGames';
import selectTopGames from '@selectors/selectTopGames';
import FavoriteHeart from '@components/FavoriteHeart';
import { useLowBalancePopup } from '@utils/hooks';
import GameThumbnail, { selectGameThumbnailProps } from './GameThumbnail';

import './style.css';

export const selectGameProps = state => ({
  isAuthenticated: state.player.isAuthenticated,
  device: state.app.device,
  liveCasinoInfoProps: selectLiveCasinoInfoProps(state),
  lastPlayedGames: selectLastPlayedGames(state),
  favoriteGames: selectFavoriteGames(state),
  topGames: selectTopGames(state),
  gameThumbnailProps: selectGameThumbnailProps(state),
});

const Game = ({
  /* props from selector */
  isAuthenticated,
  device,
  favoriteGames,

  /* props from parent */
  game,
  className,
  toggleFavoriteGameById,
  canShowVideoPreview = true,
  type,

  /* withRouter */
  location,
  history,

  liveCasinoInfoProps,
  gameThumbnailProps,
}) => {
  const previewTimer = useRef(null);
  const contentTimer = useRef(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const { play } = useUrls();

  const isVertical = type === CATEGORY_TYPES.VERTICAL;

  const showLivecasinoInfo =
    game?.liveInfo?.WagerLimits?.length > 0 ||
    game?.liveInfo?.BlackjackAvailableSeats ||
    game?.liveInfo?.BlackjackTotalSeats ||
    game?.liveInfo?.BlackjackBetBehind ||
    game?.liveInfo?.LastResults?.length > 0;

  const isFavoriteGame = isFavorite(
    favoriteGames,
    game?.backendId,
    game?.backendTableId
  );

  const shouldShowPreview = game?.preview && showPreview;

  const lowBalancePopup = useLowBalancePopup({
    goToGame: () =>
      history.push(
        play(game, {
          state: { backUrl: getLobbyUrl(game, location) },
        })
      ),
    location,
    history,
  });

  useEffect(() => {
    const currentPreviewTimer = previewTimer.current;
    const currentContentTimer = contentTimer.current;

    return () => {
      clearTimeout(currentPreviewTimer);
      clearTimeout(currentContentTimer);
    };
  }, [previewTimer, contentTimer]);

  const renderOpeningHours = StartTime => {
    const now = new Date();
    const startTime = StartTime.split(':');

    now.setHours(parseInt(startTime[0]), parseInt(startTime[1]), 0);

    let openingTime = format(now, 'YYYY-MM-DDTHH:mm:ss');

    openingTime = new Date(`${openingTime}+00:00`).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return (
      <div className="overlay">
        <img src={clock} alt="clock" loading="lazy" />
        <div>
          <Translate
            id="label.table-opens"
            values={{ openingTime }}
            defaultMessage="Table opens: {openingTime}"
          />
        </div>
      </div>
    );
  };

  const startContentTimer = () => {
    clearTimeout(contentTimer.current);
    setShowContent(true);
    contentTimer.current = setTimeout(() => {
      setShowContent(false);
    }, 2000);
  };

  const onMouseEnter = () => {
    clearTimeout(previewTimer.current);
    previewTimer.current = setTimeout(() => setShowPreview(true), 200);
    startContentTimer();
  };

  const onMouseLeave = () => {
    clearTimeout(previewTimer.current);
    setShowPreview(false);
  };

  const onMouseMove = () => {
    startContentTimer();
  };

  return (
    <div
      className={cn('Game', type, className, {
        'is-closed': !game?.liveInfo?.IsOpen && game?.liveInfo?.StartTime,
        preview: shouldShowPreview,
        'show-content': showContent && showContent && shouldShowPreview,
        'hide-content': !showContent && shouldShowPreview,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}>
      {!game?.liveInfo?.IsOpen &&
        game?.liveInfo?.StartTime &&
        renderOpeningHours(game?.liveInfo.StartTime)}
      <Link
        to={
          device === 'Desktop' && lowBalancePopup.status === 'NOT_CONFIRMED'
            ? lowBalancePopup.openLocation
            : play(game, isAuthenticated)
        }>
        <GameThumbnail
          game={game}
          isVertical={isVertical}
          canShowVideoPreview={canShowVideoPreview}
          shouldShowPreview={shouldShowPreview}
          type={type}
          {...gameThumbnailProps}
        />

        {isAuthenticated && game && (
          <Button className="Game__favorite-heart button icon">
            <FavoriteHeart
              isFavorite={isFavoriteGame}
              onClick={() =>
                toggleFavoriteGameById(game.backendId, game.backendTableId)
              }
            />
          </Button>
        )}

        <div className="game-info">
          {(game?.subprovider || game?.provider) && (
            <div className="provider">
              {game?.subprovider || game?.provider}
            </div>
          )}
          {game?.name && <div className="title">{game?.name}</div>}
          {showLivecasinoInfo && (
            <LiveCasinoInfo game={game} {...liveCasinoInfoProps} />
          )}
        </div>
      </Link>
    </div>
  );
};

export default withRouter(Game);
