import React, { Component } from 'react';
import {
  getRealityCheckSession,
  getRealityCheckData,
} from 'tg-core-redux/lib/modules/responsible_gaming/action';
import { connect } from 'react-redux';
import { Input, Button, Translate } from 'tg-core-components';
import Popup from '../../components/Popup';

import './style.css';
import { Link } from 'react-router-dom';

@connect(
  state => ({
    realityCheckSession: state.responsible_gaming.session,
    realityCheckData: state.responsible_gaming.realityCheckData,
    sessionId: state.player.sessionId,
    currency: state.player.user ? state.player.user.Currency : null,
  }),
  { getRealityCheckSession, getRealityCheckData }
)
class RealityCheck extends Component {
  static hashRoute = true;
  static globalLoader = false;

  state = {
    isChecked: false,
  };

  componentDidMount() {
    const {
      getRealityCheckData,
      getRealityCheckSession,
      sessionId,
    } = this.props;
    getRealityCheckData(sessionId);
    getRealityCheckSession(sessionId);
  }

  onClickContinue = () => {
    const { getRealityCheckSession, sessionId } = this.props;
    getRealityCheckSession(sessionId);
    window.location.hash = '';
  };

  render() {
    const {
      realityCheckSession,
      realityCheckData,
      currency,
      match,
      sessionId,
    } = this.props;
    const { isChecked } = this.state;

    const minutes =
      realityCheckSession && Math.round(realityCheckSession.SessionLenght);

    const showTable =
      realityCheckData &&
      realityCheckData.WagersWins &&
      realityCheckData.WagersWins.filter(
        wager => wager.Wager !== 0 && wager.Win !== 0
      ).length > 0;

    if (!sessionId) return (window.location.hash = '');

    return (
      <Popup
        title={
          <Translate
            id="reality-check.header"
            defaultMessage="Reality check"
            tagName="h1"
          />
        }
        preventClose={true}
        match={match}>
        <div className="RealityCheckContainer">
          <div className="time">
            <Translate
              id="label.realitycheck.sessiontime"
              values={{ minutes }}
              defaultMessage="You have now been signed in for {minutes} minutes."
            />
          </div>

          {showTable && (
            <table>
              <tbody>
                <tr>
                  <th>
                    <Translate id="label.product" defaultMessage="Product" />
                  </th>
                  <th>
                    <Translate id="label.wins" defaultMessage="Win" />
                  </th>
                  <th>
                    <Translate id="label.wager" defaultMessage="Wager" />
                  </th>
                  <th>
                    <Translate id="label.total" defaultMessage="Total" />
                  </th>
                </tr>
                {realityCheckData.WagersWins.map(
                  c =>
                    c.Wager > 0 && (
                      <tr key={c.Product}>
                        <td>{c.Product}</td>
                        <td>
                          {(Math.round(c.Win * 100) / 100).toFixed(2)}{' '}
                          {currency}
                        </td>
                        <td>
                          {(Math.round(c.Wager * 100) / 100).toFixed(2)}{' '}
                          {currency}
                        </td>
                        <td>
                          {(Math.round((c.Win - c.Wager) * 100) / 100).toFixed(
                            2
                          )}{' '}
                          {currency}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          )}

          <div className="actions">
            <Input
              type="checkbox"
              onChange={() => this.setState({ isChecked: !isChecked })}
              label={
                <Translate
                  id="label.realitycheck.accept"
                  defaultMessage="I have seen this message."
                />
              }
            />
            <Button
              disabled={!isChecked}
              onClick={this.onClickContinue}
              className="button primary wide">
              <Translate
                id="action.realitycheck.continue"
                defaultMessage="Continue"
              />
            </Button>
            <Link to="/sign-out" className="button tertiary">
              <Translate
                id="action.realitycheck.endsession"
                defaultMessage="End session"
              />
            </Link>
          </div>
        </div>
      </Popup>
    );
  }
}

export default RealityCheck;
