import React from 'react';

const CasinoPromotionSkeleton = () => (
  <div className="casino-promotion casino-promotion--skeleton">
    <div className="content-image-wrapper content-image-wrapper--skeleton shine">
      <div className="casino-promotion-image casino-promotion-image--skeleton" />
      <div className="casino-promotion-content casino-promotion-content--skeleton">
        <div className="summary summary--skeleton">
          <span className="shine"></span>
          <span className="shine"></span>
          <span className="shine"></span>
        </div>
        <div className="actions actions--skeleton shine" />
      </div>
    </div>
  </div>
);

export default CasinoPromotionSkeleton;
