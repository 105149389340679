import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import eventActions from 'tg-core-redux/lib/modules/event/action';
import { Money, Skeleton } from 'tg-core-components';
import marked from 'marked';

import './style.css';

export const selectRecentWinProps = state => ({
  recentWins: state.event.recentWins,
  games: state.games.data,
});

const TableSkeleton = ({ rows }) => (
  <table className="TableSkeleton">
    {new Array(rows).fill(null).map((_, i) => (
      <tr key={i}>
        <Skeleton.SkeletonLine />
      </tr>
    ))}
  </table>
);

const RecentWins = ({
  /* props from parent */
  limit,
  threshold,
  gameId,
  backgroundImage,
  content,
  image,

  /* props from selector */
  recentWins,
  games,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const filteredRecentWins = recentWins?.filter(win =>
    games?.some(g => g.backendId === win.GameId)
  );

  useEffect(() => {
    setIsLoading(true);

    const promise = gameId
      ? dispatch(eventActions.getRecentWinById(gameId, limit, threshold))
      : dispatch(eventActions.getRecentWins(limit, threshold));

    promise.then(() => setIsLoading(false));
  }, [dispatch, gameId, limit, threshold]);

  return (
    <div
      className="RecentWins"
      style={{
        backgroundImage:
          backgroundImage && `url('${backgroundImage.file?.url}')`,
      }}>
      <div className="col">
        {content && (
          <div
            dangerouslySetInnerHTML={{ __html: marked(content) }}
            className="RecentWins__content"
          />
        )}

        {isLoading && <TableSkeleton rows={limit} />}

        {!isLoading && filteredRecentWins.length > 0 && (
          <div className="RecentWins__items">
            {filteredRecentWins.map((win, i) => {
              const game = games?.find(g => g.backendId === win.GameId);

              const gameThumbnail = game?.thumbnail;
              const names = win.PlayerName.split(' ');
              const initials = `${names?.[0]?.charAt(0)}.${names?.[
                names.length - 1
              ]?.charAt(0)}`;

              return (
                <div className="RecentWins__item" key={i}>
                  {gameThumbnail && (
                    <div className="RecentWins__item__thumbnail">
                      <img src={gameThumbnail.file?.url} alt="thumnail" />
                    </div>
                  )}
                  <div className="RecentWins__item__name">{initials}</div>
                  <div className="RecentWins__item__game">{win.GameName}</div>
                  <div className="RecentWins__item__provider">
                    {game?.provider}
                  </div>
                  <div className="RecentWins__item__amount">
                    <Money value={win.Amount} currency={win.Currency} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {image && (
        <div className="col">
          <img
            src={image.file?.url}
            alt={image.description || image.title}
            className="RecentWins__image"
          />
        </div>
      )}
    </div>
  );
};

export default RecentWins;
