export const FORMAT = {
  JPG: 'jpeg',
  PNG: 'png',
  WEBP: 'webp',
};

export const FIT = {
  PAD: 'pad',
  FILL: 'fill',
  SCALE: 'scale',
  CROP: 'crop',
  THUMB: 'thumb',
};

export const FOCUS = {
  CENTER: 'center',
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  BOTTOM: 'bottom',
  TOP_RIGHT: 'top_right',
  TOP_LEFT: 'top_left',
  BOTTOM_RIGHT: 'bottom_right',
  BOTTOM_LEFT: 'bottom_left',
  FACE: 'face',
  FACES: 'faces',
};

/**
 * Convert image format since "jpeg" is not valid when using the Contentful API
 */
const convertFormat = format => {
  switch (format) {
    case 'jpeg':
      return 'jpg';
    default:
      return format;
  }
};

export const createImageUrl = options => {
  const {
    src,
    width,
    height,
    quality,
    progressive,
    simple,
    format,
    background,
    radius,
    focus,
    fit,
  } = options;

  let url = `${src}`;
  const params = [];

  if (format) {
    params.push(`fm=${convertFormat(format)}`);
  }

  if (quality) {
    params.push(`q=${quality}`);
  }

  if (width) {
    params.push(`w=${width}`);
  }

  if (height) {
    params.push(`h=${height}`);
  }

  if (progressive) {
    params.push(`fl=progressive`);
  }

  if (background) {
    params.push(`bg=${background}`);
  }

  if (radius) {
    params.push(`r=${radius}`);
  }

  if (focus) {
    params.push(`f=${focus}`);
  }

  if (fit) {
    params.push(`fit=${fit}`);
  }

  if (simple) {
    params.push(`format=png8`);
  }

  if (params.length) {
    url += `?${params.join('&')}`;
  }

  return url;
};
