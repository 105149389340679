import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Loader, Translate } from 'tg-core-components';
import { createLaunchUrl } from '@utils/game';
import { GamePlayContext, setIsGameLaunched } from '../context';
import { useConfig } from '@utils/config';
import { useInnerDimensions } from '@utils/hooks';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import translate from 'tg-core-components/lib/lib/utils/translate';
import { setActiveCurrency } from 'tg-core-redux/lib/modules/player/action';
import CurrencySelector from '@components/CurrencySelector';
import { useUrls } from '@utils/urls';
import { isGameDisabled } from '../helpers';

import './style.css';

export const selectGameFrameProps = state => ({
  isAuthenticated: state.player.isAuthenticated,
  sessionId: state.player.sessionId,
  jurisdiction: state.app.jurisdiction,
  locale: state.app.locale,
  device: state.app.device,
  displayCurrencies: state.currencies.displayCurrencies,
  activeDisplayCurrency: state.player.activeCurrency,
  providerCurrencies:
    state.content.config.data?.find(c => c.key === 'provider-currencies') || [],
  user: state.player.user,
  ipCountry: state.app.ipCountry,
  cmsConfig: state.content.config.data,
});

const GameFrame = ({
  /* Props from parent */
  game,
  onExitGame,

  /* Props from selector */
  isAuthenticated,
  sessionId,
  jurisdiction,
  locale,
  device,
  intl,
  displayCurrencies,
  providerCurrencies,
  activeDisplayCurrency,
  location,
  user,
  ipCountry,
  cmsConfig,
}) => {
  const { state, dispatch } = useContext(GamePlayContext);
  const reduxDispatch = useDispatch();

  const iframeRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [launchUrl, setLaunchUrl] = useState(null);
  const [innerWidth, innerHeight] = useInnerDimensions();
  const config = useConfig();
  const { deposit, signIn } = useUrls();

  const showDisabledGameMessage =
    config.showDisabledGames &&
    isGameDisabled({
      game,
      userCountry: user?.Country,
      ipCountry,
      isAuthenticated,
      userZip: user?.Zip,
      cmsConfig,
    });

  /**
   * Make sure the launch url update when params do
   */
  useEffect(() => {
    setLaunchUrl(
      createLaunchUrl({
        config,
        game,
        jurisdiction,
        locale,
        sessionId: isAuthenticated ? sessionId : null,
        activeDisplayCurrency,
      })
    );
  }, [config, game, jurisdiction, locale, sessionId, activeDisplayCurrency]);

  /**
   * Start loading when launchUrl changes
   */
  useEffect(() => {
    setIsLoading(true);
  }, [launchUrl]);

  /**
   * When spanish player launches a game on mobile -> send to tgplay
   */
  useEffect(() => {
    if (
      ['Mobile', 'Tablet'].includes(device) &&
      ['es'].includes((jurisdiction || '').toLowerCase()) &&
      launchUrl
    ) {
      window.location.href = launchUrl;
    }
  }, [device, jurisdiction, launchUrl]);

  const onIframeLoad = () => {
    setIsLoading(false);

    dispatch(setIsGameLaunched(true));

    /**
     * If same origin is opened in the iframe, most likely
     * something went wrong with homeurl in tg-play
     * -> return the player to casino lobby
     */
    const isSameOrigin = () => {
      try {
        return launchUrl && Boolean(iframeRef.current.contentDocument);
      } catch (e) {
        return false;
      }
    };
    if (iframeRef && isSameOrigin()) onExitGame();
  };

  if (!isAuthenticated && (game.isLive || config.disableDemoPlay)) {
    const refUrl = __CLIENT__
      ? encodeURI(location.pathname + location.search + location.hash)
      : null;

    return (
      <div className="GameFrame">
        <div className="GameFrame__require-sign-in-message">
          <Translate
            id="label.gameplay.require-sign-in"
            defaultMessage="Please {link} to play this game."
            values={{
              link: config.isAccount ? (
                <Link to={signIn()}>
                  {translate(
                    {
                      id: 'action.game.sign-in',
                      defaultMessage: 'sign in',
                    },
                    intl
                  )}
                </Link>
              ) : (
                <Link
                  to={deposit({
                    search: `?ref_url=${encodeURIComponent(refUrl)}`,
                  })}>
                  {translate(
                    {
                      id: 'action.game.deposit',
                      defaultMessage: 'deposit',
                    },
                    intl
                  )}
                </Link>
              ),
            }}
          />
        </div>
      </div>
    );
  }

  /**
   * If it's a multiCurrency casino and the selected currency is not available for this game, select another one.
   */
  const subproviderKey = (game.subprovider || game.provider)?.toLowerCase();
  // Get supported currencis for this gsp or fallback to default

  let currencies = providerCurrencies?.value
    ? providerCurrencies?.value[subproviderKey] ||
      providerCurrencies?.value['default']
    : [];

  // Filter out gsp supported currencis with aleacc supported.
  currencies = currencies.filter(pc =>
    displayCurrencies.find(dc => pc.toUpperCase() === dc.toUpperCase())
  );

  const mainCurrenciesCount = providerCurrencies?.value?.mainCurrencies;

  if (showDisabledGameMessage) {
    return (
      <div className="GameFrame">
        <div className="GameFrame__disabled-game">
          <Translate
            id="label.disabled-game"
            defaultMessage="This game is not available in your country."
          />
        </div>
      </div>
    );
  }

  if (
    config.multiCurrency &&
    activeDisplayCurrency &&
    currencies.length > 0 &&
    !currencies.includes(activeDisplayCurrency)
  ) {
    return (
      <div className="GameFrame">
        <div className="GameFrame__require-change-currency-message">
          <Translate
            id="label.gameplay.change-currency"
            defaultMessage="This game does not support { activeDisplayCurrency }. Please select another currency to start the game."
            values={{
              activeDisplayCurrency,
            }}
          />

          <CurrencySelector
            currency={null}
            expanded={true}
            expandable={false}
            currencies={currencies.map(c => ({ code: c, name: c }))}
            mainCurrencies={mainCurrenciesCount > 0}
            onSelect={c => {
              reduxDispatch(setActiveCurrency(c, sessionId));
            }}
            mainCurrenciesCount={mainCurrenciesCount}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="GameFrame">
      {isLoading && (
        <div className="GameFrame__loader">
          <Loader inline />
        </div>
      )}
      <div className="GameFrame__iframe">
        {/* For mobile we want to use inner dimensions
        of window to size the iframe. This makes sure
        iframe does not overflow viewport height */}
        {launchUrl &&
          ['Mobile', 'Tablet'].includes(device) &&
          (!state.isBottomBarOpen || state.isGameLaunched) && (
            <iframe
              className="GameFrame__mobile-iframe"
              style={{
                '--inner-width': `${innerWidth}px`,
                '--inner-height': `${innerHeight}px`,
              }}
              src={launchUrl}
              onLoad={onIframeLoad}
              ref={iframeRef}
            />
          )}
        {/* For desktop we make iframe keep aspect ratio
        as speicifed for each game in cms */}
        {['Desktop'].includes(device) && (
          <AspectRatio ratio={game.aspectRatio || '16:9'}>
            <iframe
              className="GameFrame__desktop-iframe"
              src={launchUrl}
              onLoad={onIframeLoad}
              ref={iframeRef}
            />
          </AspectRatio>
        )}
      </div>
    </div>
  );
};

/**
 * AspectRatio will render a child 'frame' that will always keep the given ratio width / height
 */
const AspectRatio = ({ children, ratio }) => {
  const [widthRatio, heightRatio] = ratio.split(':');
  const aspectRatioRef = useRef();
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const resizeObserver =
    typeof ResizeObserver === 'function' &&
    new ResizeObserver(entries => {
      for (const entry of entries) {
        setWidth(
          Math.min(
            entry.contentRect.width,
            entry.contentRect.height * (widthRatio / heightRatio)
          )
        );
        setHeight(
          Math.min(
            entry.contentRect.height,
            entry.contentRect.width * (heightRatio / widthRatio)
          )
        );
      }
    });
  useEffect(() => {
    const ref = aspectRatioRef.current;
    resizeObserver?.observe(ref);
    return () => resizeObserver?.unobserve(ref);
  }, [resizeObserver]);
  return (
    <div className="AspectRatio" ref={aspectRatioRef}>
      <div
        className="AspectRatio__frame"
        style={{
          width: width ? `${width}px` : '100%',
          height: height ? `${height}px` : '100%',
        }}>
        {children}
      </div>
    </div>
  );
};

export default compose(injectIntl)(GameFrame);
