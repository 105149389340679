import React, { Component } from 'react';
import compose from 'recompose/compose';
import { injectIntl } from 'react-intl';
import { Input, Button, Translate } from 'tg-core-components';
import WithValidation from 'tg-core-components/lib/lib/WithValidation';
import require from 'tg-core-components/lib/lib/WithValidation/rules/required';
import email from 'tg-core-components/lib/lib/WithValidation/rules/email';
import password from 'tg-core-components/lib/lib/WithValidation/rules/password';
import match from 'tg-core-components/lib/lib/WithValidation/rules/match';
import phone from 'tg-core-components/lib/lib/WithValidation/rules/phone';
import api from 'tg-core-api';
import getByPath from 'lodash/get';
import PasswordFeedback from '@components/PasswordFeedback';
import translate from 'tg-core-components/lib/lib/utils/translate';
import getCallingCodeOptions from '@utils/callingCodeOptions';

import './UserInfoStep.css';

class UserInfoStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      Email,
      MobilePhoneNumber,
      updateSubmitData,
      Password,
      confirm_password,
    } = this.props;

    // If we don't validate these fields the submit button will be disabled unitil the "blur" event is fired.
    this._validateEmail(Email || '');
    this._validatePhone(MobilePhoneNumber || '');

    if (Password) updateSubmitData({}, 'Password', '');
    if (confirm_password) updateSubmitData({}, 'confirm_password', '');
  }

  _validateEmail = async email => {
    if (email.trim()) {
      this.setState({ emailValidation: 'loading' });
      try {
        await api.player.validateEmail(
          encodeURIComponent(email),
          this.props.jurisdiction
        );
        this.setState({ emailValidation: 'success' });
      } catch (e) {
        const code = getByPath(e, 'value.response.data.Code', '');
        if (code === 'RateLimited') {
          this.setState({ emailValidation: 'success' });
        } else {
          this.setState({ emailValidation: 'failure' });
        }
      }
    }
  };

  _validatePassword = password =>
    this.setState({
      passwordValidation:
        (password || '').toLowerCase() !==
        (this.props.Email || '').toLowerCase()
          ? 'success'
          : 'failure',
    });

  _validatePhone = async phonenumber => {
    if (phonenumber?.length > 5) {
      this.setState({ phoneValidation: 'loading' });
      try {
        await api.player.validatePhone(phonenumber, this.props.jurisdiction);
        this.setState({ phoneValidation: 'success' });
      } catch (e) {
        const code = getByPath(e, 'value.response.data.Code', '');
        if (code === 'RateLimited') {
          this.setState({ phoneValidation: 'success' });
        } else {
          this.setState({ phoneValidation: 'failure' });
        }
      }
    }
  };

  render() {
    const {
      errors,
      onSubmit,
      intl,
      onBlur,
      countries,
      Email,
      MobilePhoneNumber,
      updateSubmitData,
      Password,
      confirm_password,
      callingCodes,
    } = this.props;

    const { emailValidation, passwordValidation, phoneValidation } = this.state;

    const callingCodeOptions = getCallingCodeOptions(countries, callingCodes);

    const submitButton = (
      <Button
        disabled={
          emailValidation !== 'success' ||
          passwordValidation !== 'success' ||
          phoneValidation !== 'success' ||
          Object.keys(errors).length > 0
        }
        className="button primary wide"
        type="submit">
        {translate({ id: 'action.button.continue' }, intl)}
      </Button>
    );

    return (
      <form className="Step1 form signup-form" onSubmit={onSubmit}>
        <Input
          autoFocus
          name="Email"
          label={translate(
            {
              id: 'title.email',
              defaultMessage: 'Email',
            },
            intl
          )}
          type="email"
          value={Email || ''}
          onChange={(e, email) => updateSubmitData(e, 'Email', email)}
          onBlur={() => {
            onBlur('Email', Email);
            this._validateEmail(Email || '');
          }}
          status={errors.Email ? 'failure' : emailValidation}
          statusText={
            (errors.Email && translate({ id: errors.Email }, intl)) ||
            (emailValidation === 'error' &&
              translate(
                {
                  id: 'errors.taken.email',
                  defaultMessage: 'Email is already taken.',
                },
                intl
              ))
          }
        />

        <Input
          name="MobilePhoneNumber"
          type="tel"
          value={MobilePhoneNumber || ''}
          onChange={(e, phonenumber) => {
            updateSubmitData(e, 'MobilePhoneNumber', phonenumber);
          }}
          callingCodes={callingCodeOptions}
          onBlur={() => {
            onBlur('MobilePhoneNumber', MobilePhoneNumber);
            this._validatePhone(MobilePhoneNumber);
          }}
          maxLength="17"
          status={errors.MobilePhoneNumber ? 'failure' : phoneValidation}
          statusText={
            (errors.MobilePhoneNumber &&
              translate({ id: errors.MobilePhoneNumber }, intl)) ||
            (phoneValidation === 'error' &&
              translate(
                {
                  id: 'errors.taken.phone',
                  defaultMessage: 'Phone number is already taken.',
                },
                intl
              ))
          }
        />
        <Input
          name="Password"
          label={translate(
            {
              id: 'title.choose_password',
              defaultMessage: 'Choose password',
            },
            intl
          )}
          type="password"
          value={Password || ''}
          onChange={(e, password) => updateSubmitData(e, 'Password', password)}
          onBlur={() => {
            onBlur('Password', Password);
            this._validatePassword(Password);
          }}
          status={errors.Password ? 'failure' : passwordValidation}
          statusText={
            (errors.Password && translate({ id: errors.Password }, intl)) ||
            (passwordValidation === 'failure' &&
              translate(
                {
                  id: 'errors.password-equal-email',
                  defaultMessage: 'Password cannot be equal to email',
                },
                intl
              ))
          }
        />

        <div className="password-hint">
          <Translate
            id="label.password-hint"
            defaultMessage="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 digit and 1 special character"
            tagName="p"
          />
        </div>
        <PasswordFeedback password={Password} />

        <Input
          name="confirm_password"
          label={translate(
            {
              id: 'title.confirm_password',
              defaultMessage: 'Confirm password',
            },
            intl
          )}
          type="password"
          value={confirm_password || ''}
          onChange={(e, confirm_password) =>
            updateSubmitData(e, 'confirm_password', confirm_password)
          }
          onBlur={() => onBlur('confirm_password', confirm_password)}
          status={
            (errors.confirm_password && 'failure') ||
            (confirm_password && confirm_password !== Password && 'failure') ||
            (confirm_password && 'success') ||
            'idle'
          }
          statusText={
            (errors.confirm_password ||
              (confirm_password && confirm_password !== Password)) &&
            translate(
              { id: errors.confirm_password || 'error.match.password' },
              intl
            )
          }
        />
        <div className="layout-item-full-width promo-button">
          {submitButton}
        </div>
      </form>
    );
  }
}

const rules = {
  Email: [
    [require, 'error.empty.email'],
    [email, 'error.condition.email'],
  ],
  MobilePhoneNumber: [
    [require, 'error.empty.phone'],
    [phone, 'error.condition.phone'],
  ],
  Password: [
    [require, 'error.empty.password'],
    [password, 'error.condition.password'],
  ],
  confirm_password: [[match('Password'), 'error.match.password']],
};

export default compose(WithValidation(rules), injectIntl)(UserInfoStep);
