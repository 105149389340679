// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'mga',
  chat: {
    provider: 'zendesk',
    host: 'https://static.zdassets.com/ekr/snippet.js?key=9b657de7-dde0-4ac4-801d-80dbf0ef16ba',
    secret:
      '9DC9F1C05BCE1B215BB934F9A2256FDA5DDBE0FF22DDE0FD8348098AD04A3CC1',
    departments:
      country === 'se'
        ? ['Turbo Vegas Svensk Support']
        : [
            'TurboVegas English Support',
            'TurboVegas Deutscher Kundenservice',
            'TurboVegas Asiakaspalvelu Suomeksi',
            'TurboVegas Norsk Kundeservice',
            'TurboVegas Soporte en Español',
            'TurboVegas Suporte Portugues',
          ],
    tags: country === 'se' ? ['turbovegas'] : ['turbovegasglobal'],
    colors: {
      theme: '#ff5850',
      launcher: '#ff5850',
      launcherText: '#ffffff',
      button: '#ff5850',
    },
  },
});
