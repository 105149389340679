import { fetchContent, fetchTranslation } from './actions/content';
import { fetchGames } from './actions/games';
import Cookies from 'universal-cookie';
import playerGroupActions from 'tg-core-redux/lib/modules/player-groups/action';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import kycActions from 'tg-core-redux/lib/modules/kyc/action';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import walletActions from 'tg-core-redux/lib/modules/wallet/action';
import casinoActions from 'tg-core-redux/lib/modules/casino/action';
import internalMessageActions from 'tg-core-redux/lib/modules/internal_message/action';
import playerPropertiesAction from 'tg-core-redux/lib/modules/player_properties/action';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import exchangeRatesActions from 'tg-core-redux/lib/modules/exchange-rates/action';
import currenciesActions from 'tg-core-redux/lib/modules/currencies/action';
import deviceDetect from './utils/deviceDetect';
import { getPayments } from 'tg-core-redux/lib/modules/transaction/action';
import { filterOutContentBySegmentation } from '@actions/content';
import { bootstrap } from '@actions/app';
import { setGlobalConfig, getConfig } from '@config';
import uaParser from 'ua-parser-js';

export default async function(store, options) {
  const state = store.getState();
  const cookies = new Cookies();
  const {
    app: { isServerSideRender, locale, jurisdiction },
    player,
  } = state;
  const promises = [];

  setGlobalConfig(jurisdiction);

  store.dispatch({
    type: 'BOOTSTRAP_INIT',
  });

  const sessionId = __CLIENT__ ? player.sessionId : options.sessionId;
  const uaParsed = __CLIENT__ ? uaParser(window.navigator.userAgent) : null;

  const userAgentData = {
    device: __CLIENT__
      ? deviceDetect(window.navigator.userAgent)
      : options.device,
    browser: __CLIENT__ ? uaParsed.browser.name : options.browser,
    operatingSystem: __CLIENT__ ? uaParsed.os.name : options.operatingSystem,
  };

  store.dispatch({
    type: 'SET_USERAGENT_DATA',
    payload: userAgentData,
  });

  if (__SERVER__ || !isServerSideRender) {
    promises.push(store.dispatch(fetchContent('menu', locale, {}, options)));
    promises.push(store.dispatch(fetchTranslation(locale, options)));
    promises.push(
      store.dispatch(fetchTranslation(locale, options, { skip: 1000 }))
    );
    promises.push(store.dispatch(fetchContent('config', locale, {}, options)));
    promises.push(store.dispatch(fetchContent('payment', locale, {}, options)));
    promises.push(
      store.dispatch(
        fetchContent('language', locale, { order: '-fields.order' }, options)
      )
    );
    promises.push(
      store.dispatch(fetchContent('tournament', locale, {}, options))
    );
  }

  if (__CLIENT__) {
    // Do this here to make sure we get the player's IP and not proxy/server IP.
    promises.push(store.dispatch(bootstrap()));
    promises.push(store.dispatch(exchangeRatesActions.getExchangeRates()));
    promises.push(store.dispatch(currenciesActions.getDisplayCurrencies()));
    promises.push(store.dispatch(currenciesActions.getCurrencies()));
  }

  if (__CLIENT__ && sessionId) {
    promises.push(
      store.dispatch(
        bonusActions.getBonuses(sessionId, getConfig().bonusProvider)
      )
    );
    promises.push(store.dispatch(playerGroupActions.getGroups(sessionId)));
    promises.push(store.dispatch(walletActions.getWallet(sessionId)));
    promises.push(store.dispatch(walletActions.getWallets(sessionId)));
    promises.push(store.dispatch(playerActions.getActiveCurrency(sessionId)));
    promises.push(store.dispatch(playerActions.userInfo(sessionId)));
    promises.push(store.dispatch(playerActions.paymentStats(sessionId)));
    promises.push(store.dispatch(kycActions.getKyc(sessionId)));
    promises.push(
      store.dispatch(playerPropertiesAction.getProperties(sessionId))
    );
    promises.push(store.dispatch(responsibleGamingActions.getLimit(sessionId)));
    promises.push(
      store.dispatch(responsibleGamingActions.getProductBlocks(sessionId))
    );
    promises.push(store.dispatch(casinoActions.getFavorites(sessionId)));
    promises.push(
      store.dispatch(
        casinoActions.getLastPlayedGames(
          sessionId,
          deviceDetect(window.navigator.userAgent)
        )
      )
    );
    promises.push(store.dispatch(getPayments(sessionId, 10, 0, 'Withdraw')));
    promises.push(
      store.dispatch(responsibleGamingActions.getRealityCheckSession(sessionId))
    );

    getConfig().imProvider !== 'fasttrack' &&
      promises.push(
        store.dispatch(
          internalMessageActions.getMessages(sessionId, getConfig().imProvider)
        )
      );

    // dispatch devcode get payment methods
    // dispatch player get bonuses

    if (__CLIENT__) {
      cookies.set('sessionId', sessionId, {
        path: '/',
        domain: getConfig().cookieDomain(),
        expires: new Date(
          Date.now() + (getConfig()?.sessionCookieMaxAge || 9000000 * 2)
        ),
        secure: getConfig().CURRENT_ENV === 'prod',
      });
    }
  }

  return Promise.all(promises)
    .then(() => {
      store.dispatch(filterOutContentBySegmentation(undefined, options));
      store.dispatch({
        type: 'BOOTSTRAP_DONE',
      });
    })
    .catch(error => {
      store.dispatch({
        type: 'BOOTSTRAP_FAILED',
        payload: error,
      });
    });
}
