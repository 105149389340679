import React, { useContext, Fragment } from 'react';
import { Button, Icon, Translate, Skeleton } from 'tg-core-components';
import QuickDeposit, {
  selectQuickDepositProps,
} from '@components/QuickDeposit';
import QuickDepositMobile, {
  selectQuickDepositMobileProps,
} from '@components/QuickDepositMobile';
import MembershipActions, {
  selectMembershipActionsProps,
} from '@components/Header/MembershipActions';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import selectUnreadNotifications from '@selectors/selectUnreadNotifications';
import { getLobbyUrl } from '@utils/game';
import { GamePlayContext, setIsBottomBarOpen } from '../context';
import { withConfig } from '@utils/config';
import { useUrls } from '@utils/urls';
import hamburgerIcon from '@images/hamburger-icon.svg';

import './style.css';

export const selectGamePlayHeaderProps = state => ({
  device: state.app.device,
  isAuthenticated: state.player.isAuthenticated,
  user: state.player.user,
  unreadNotifications: selectUnreadNotifications(state),
  quickDepositProps: selectQuickDepositProps(state),
  quickDepositMobileProps: selectQuickDepositMobileProps(state),
  membershipActionsProps: selectMembershipActionsProps(state),
});

const UserSkeleton = () => (
  <div className="UserSkeleton">
    <Skeleton.SkeletonCircle />
    <Skeleton.SkeletonLine bold />
  </div>
);

const DepositButtons = ({ device, refUrl }) => {
  const { deposit, depositString } = useUrls();

  return device === 'Desktop' ? (
    <div className="QuickDeposit">
      <form>
        <Button
          href={depositString({
            search: `?ref_url=${encodeURIComponent(refUrl)}`,
          })}
          className="button button--icon button--secondary button--right">
          <Translate defaultMessage="Deposit" id="action.deposit" />
          <Icon className="icon-coins" icon="coins" />
        </Button>
      </form>
    </div>
  ) : (
    <div className="QuickDepositMobile">
      <Link
        className="QuickDepositMobile__icon button icon"
        to={deposit({ search: `?ref_url=${encodeURIComponent(refUrl)}` })}>
        <Icon icon="coins" />
      </Link>
    </div>
  );
};

const GamePlayHeader = ({
  /* Props from parent */
  game,
  pushGtmEvent,
  quickDepositInit,
  quickDepositOpen,

  /* Props from selector */
  device,
  isAuthenticated,
  user,
  unreadNotifications,

  /* withRouter */
  location,

  /* withConfig */
  config,

  quickDepositProps,
  quickDepositMobileProps,
  membershipActionsProps,
}) => {
  const { state, dispatch } = useContext(GamePlayContext);

  const depositRefUrl = __CLIENT__
    ? encodeURI(location.pathname + location.search + location.hash)
    : null;

  return (
    <div className="GamePlayHeader">
      <div className="GamePlayHeader__actions">
        <Link
          className="GamePlayHeader__back"
          to={{ pathname: getLobbyUrl(game, location) }}>
          <Button className="button icon">
            <Icon icon="chevron-left" />
          </Button>
          <Translate id="action.back_to_lobby" defaultMessage="Back to lobby" />
        </Link>

        {!state.isBottomBarOpen && state.isGameLaunched && (
          <Button
            className="GamePlayHeader__menu button icon"
            onClick={() => dispatch(setIsBottomBarOpen(true))}>
            <Icon icon="gameplay-menu" />
          </Button>
        )}

        {state.isBottomBarOpen && state.isGameLaunched && (
          <Button
            className="GamePlayHeader__menu-close button icon"
            onClick={() => dispatch(setIsBottomBarOpen(false))}>
            <Icon icon="times" />
          </Button>
        )}
      </div>

      {config.isAccount && isAuthenticated && (
        <>
          {!user && <UserSkeleton />}

          {user && (
            <div className="GamePlayHeader__user">
              <Link className="button icon" to={{ hash: 'dashboard' }}>
                <Icon icon="avatar" />
                {unreadNotifications.internalMessages > 0 && (
                  <span
                    className="notifications-badge notifications-badge--right"
                    data-notifications={unreadNotifications.internalMessages}
                  />
                )}
              </Link>
              <Link
                to={{ hash: 'dashboard' }}
                className="GamePlayHeader__name">{`${user.FirstName} ${user.LastName}`}</Link>
            </div>
          )}
        </>
      )}

      {!isAuthenticated && (
        <div className="GamePlayHeader__demo-text">
          <Translate
            id="label.gameplay-demo"
            defaultMessage="You are playing for fun. {Link}"
            values={{
              Link: (
                <Link to={config?.isPnp ? '/start-playing' : '#sign-in'}>
                  <Translate
                    id="action.gameplay-demo"
                    defaultMessage="Press here to play for real"
                  />
                </Link>
              ),
            }}
          />
        </div>
      )}

      {!isAuthenticated && (
        <MembershipActions
          {...membershipActionsProps}
          ref_url={`/play/${game.url}`}
        />
      )}

      {isAuthenticated && (
        <div className="GamePlayHeader__quick-deposit">
          {config.quickDeposit?.enabled ? (
            <Fragment>
              {device === 'Desktop' && (
                <div className="GamePlayHeader__quick-deposit-desktop">
                  <QuickDeposit
                    {...quickDepositProps}
                    quickDepositInit={quickDepositInit}
                    pushGtmEvent={pushGtmEvent}
                  />
                </div>
              )}
              {['Mobile', 'Tablet'].includes(device) && (
                <QuickDepositMobile
                  {...quickDepositMobileProps}
                  quickDepositInit={quickDepositInit}
                  quickDepositOpen={quickDepositOpen}
                />
              )}
            </Fragment>
          ) : (
            <DepositButtons refUrl={depositRefUrl} device={device} />
          )}
        </div>
      )}

      {config.isPnp && (
        <div className="GamePlayHeader__hamburger">
          <Link to={{ hash: 'dashboard' }} className="hamburger-icon">
            <img src={hamburgerIcon} alt="menu" />
            <span
              className="notifications-badge notifications-badge--right"
              data-notifications={unreadNotifications.internalMessages}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default withConfig(withRouter(GamePlayHeader));
