import React from 'react';
import { Input, Button } from 'tg-core-components';
import {
  validateRules,
  phone,
  required,
  externalPhoneValidation,
} from '../../validation';
import translate from 'tg-core-components/lib/lib/utils/translate';
import cn from 'classnames';
import { getContinueLabel } from '../../helpers';
import uniqBy from 'lodash/uniqBy';

const Phone = ({
  /* Props from parent */
  errors,
  onChange,
  intl,
  fields,
  isLoading,
  nextStep,
  statuses,

  /* Props from selector */
  countries,
  callingCodes,
  device,
  ipCountry,
}) => {
  const isValid = statuses.MobilePhoneNumber === 'success';

  const allCallingCodes = (callingCodes?.value?.length
    ? callingCodes.value
    : countries?.value
  )
    .sort((a, b) => Number(a.callingCode) - Number(b.callingCode))
    .sort((a, b) =>
      (a.value || a.countryCode) === ipCountry
        ? -1
        : (b.value || b.countryCode) === ipCountry
        ? 1
        : 0
    )
    .map(i => ({
      value: '00' + i.callingCode,
      label: `+${i.callingCode}`,
    }));

  return (
    <form onSubmit={e => (isValid ? nextStep() : e.preventDefault())}>
      <Input
        autoFocus
        name="MobilePhoneNumber"
        type="tel"
        value={fields.MobilePhoneNumber || ''}
        onChange={(_, MobilePhoneNumber) => {
          onChange({ MobilePhoneNumber });
        }}
        callingCodes={uniqBy(allCallingCodes, 'value')}
        maxLength="17"
        status={
          fields.MobilePhoneNumber?.length > 6 &&
          ((isLoading && 'loading') || statuses.MobilePhoneNumber)
        }
        statusText={
          fields.MobilePhoneNumber?.length > 6 &&
          errors.MobilePhoneNumber &&
          translate({ id: errors.MobilePhoneNumber }, intl)
        }
      />

      <Button
        disabled={!isValid}
        className={cn('continue primary', {
          'continue--valid': isValid,
        })}>
        {getContinueLabel(device)}
      </Button>
    </form>
  );
};

const validate = async ({ MobilePhoneNumber }, jurisdiction) => {
  const rules = {
    MobilePhoneNumber: [
      [required, 'error.empty.phone'],
      [phone, 'error.condition.phone'],
      [externalPhoneValidation(jurisdiction), 'error.taken.phone'],
    ],
  };
  const errors = await validateRules({ MobilePhoneNumber }, rules);

  return Promise.resolve({ errors });
};

const selector = state => ({
  device: state.app.device,
  countries: state.content.config.data?.find(c => c.key === 'countries'),
  callingCodes: state.content.config.data?.find(c => c.key === 'callingCodes'),
  ipCountry: state.app.ipCountry,
});

export default {
  name: 'phone',
  icon: 'app',
  Component: Phone,
  validate,
  selector,
  fields: ['MobilePhoneNumber'],
};
