import { bindReferences } from '@utils/segmentation';
import identity from 'lodash/identity';
import omit from 'lodash/omit';

const defaultContent = {
  pages: { data: null, updated: 0, isLoading: false },
  menus: { data: null, updated: 0, isLoading: false },
  sections: { data: null, updated: 0, isLoading: false },
  sets: { data: null, updated: 0, isLoading: false },
  languages: { data: null, updated: 0, isLoading: false },
  config: { data: null, updated: 0, isLoading: false },
  promotions: { data: null, updated: 0, isLoading: false },
  promotionCategories: { data: null, updated: 0, isLoading: false },
  bonuses: { data: null, updated: 0, isLoading: false },
  payments: { data: null, updated: 0, isLoading: false },
  banners: { data: null, updated: 0, isLoading: false },
  teamMembers: { data: null, updated: 0, isLoading: false },
  affiliates: { data: null, updated: 0, isLoading: false },
  blogPosts: { data: null, updated: 0, isLoading: false },
  blogCategories: { data: null, updated: 0, isLoading: false },
  tournaments: { data: null, updated: 0, isLoading: false },
};

const initialState = {
  originalContent: defaultContent,
  ...defaultContent,
};

const IGNORE_TRANSFORMATION = [];
const handleContent = (
  contentType,
  { state, action, postProcessing = identity, segmentation = true }
) => {
  let normalizedData = bindReferences(action.payload).items.map(i => i.fields);
  normalizedData = postProcessing(normalizedData);
  const originalContent = state.originalContent[contentType];

  if (
    originalContent &&
    originalContent.data &&
    IGNORE_TRANSFORMATION.indexOf(contentType) === -1
  ) {
    // transform data from array to hash
    const missingItems = [];

    normalizedData.forEach(item => {
      if (!originalContent.data.find(i => i.identifier === item.identifier)) {
        missingItems.push(item);
      }
    });

    normalizedData = originalContent.data.concat(missingItems);
  }

  var data = {
    updated: Date.now(),
    data: normalizedData,
    isLoading: false,
  };

  if (!segmentation) {
    return {
      ...state,
      [contentType]: data,
      originalContent: {
        ...state.originalContent,
        [contentType]: data,
      },
    };
  }

  return {
    ...state,
    originalContent: {
      ...state.originalContent,
      [contentType]: data,
    },
  };
};

const handleContentStart = (contentType, state) => {
  return {
    ...state,
    [contentType]: {
      ...state[contentType],
      isLoading: true,
    },
  };
};

const handleContentFailure = (contentType, state) => {
  return {
    ...state,
    [contentType]: {
      ...state[contentType],
      isLoading: false,
    },
  };
};

const processMenu = data => {
  return data.map(menuItem => {
    if (!menuItem.items) return menuItem;
    return {
      ...menuItem,
      items: menuItem.items.map(subItem => omit(subItem, ['content'])),
    };
  });
};

const actionTypeToContentTypeMapping = {
  CONTENT_FETCH_TEAMMEMBER: 'teamMembers',
  CONTENT_FETCH_PAGE: 'pages',
  CONTENT_FETCH_MENU: 'menus',
  CONTENT_FETCH_BANNER: 'banners',
  CONTENT_FETCH_SET: 'sets',
  CONTENT_FETCH_CONFIG: 'config',
  CONTENT_FETCH_SECTION: 'sections',
  CONTENT_FETCH_LANGUAGE: 'languages',
  CONTENT_FETCH_PROMOTION: 'promotions',
  CONTENT_FETCH_PROMOTIONCATEGORY: 'promotionCategory',
  CONTENT_FETCH_BONUS: 'bonuses',
  CONTENT_FETCH_PAYMENT: 'payments',
  CONTENT_FETCH_AFFILIATELANDINGPAGE: 'affiliates',
  CONTENT_FETCH_BLOGPOST: 'blogPosts',
  CONTENT_FETCH_BLOGCATEGORY: 'blogCategories',
  CONTENT_FETCH_TOURNAMENT: 'tournaments',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CONTENT_FETCH_TEAMMEMBER_FAILURE':
    case 'CONTENT_FETCH_PAGE_FAILURE':
    case 'CONTENT_FETCH_MENU_FAILURE':
    case 'CONTENT_FETCH_BANNER_FAILURE':
    case 'CONTENT_FETCH_SET_FAILURE':
    case 'CONTENT_FETCH_CONFIG_FAILURE':
    case 'CONTENT_FETCH_SECTION_FAILURE':
    case 'CONTENT_FETCH_LANGUAGE_FAILURE':
    case 'CONTENT_FETCH_PROMOTION_FAILURE':
    case 'CONTENT_FETCH_PROMOTIONCATEGORY_FAILURE':
    case 'CONTENT_FETCH_BONUS_FAILURE':
    case 'CONTENT_FETCH_PAYMENT_FAILURE':
    case 'CONTENT_FETCH_AFFILIATELANDINGPAGE_FAILURE':
    case 'CONTENT_FETCH_BLOGPOST_FAILURE':
    case 'CONTENT_FETCH_BLOGCATEGORY_FAILURE':
    case 'CONTENT_FETCH_TOURNAMENT_FAILURE':
      return handleContentFailure(
        actionTypeToContentTypeMapping[
          action.type.replace(/_[FAILURE|START]+$/, '')
        ],
        state
      );

    case 'CONTENT_FETCH_TEAMMEMBER_START':
    case 'CONTENT_FETCH_PAGE_START':
    case 'CONTENT_FETCH_MENU_START':
    case 'CONTENT_FETCH_BANNER_START':
    case 'CONTENT_FETCH_SET_START':
    case 'CONTENT_FETCH_CONFIG_START':
    case 'CONTENT_FETCH_SECTION_START':
    case 'CONTENT_FETCH_LANGUAGE_START':
    case 'CONTENT_FETCH_PROMOTION_START':
    case 'CONTENT_FETCH_PROMOTIONCATEGORY_START':
    case 'CONTENT_FETCH_BONUS_START':
    case 'CONTENT_FETCH_PAYMENT_START':
    case 'CONTENT_FETCH_AFFILIATELANDINGPAGE_START':
    case 'CONTENT_FETCH_BLOGPOST_START':
    case 'CONTENT_FETCH_BLOGCATEGORY_START':
    case 'CONTENT_FETCH_TOURNAMENT_START':
      return handleContentStart(
        actionTypeToContentTypeMapping[
          action.type.replace(/_[FAILURE|START]+$/, '')
        ],
        state
      );

    case 'CONTENT_FETCH_TEAMMEMBER_COMPLETE':
      return handleContent('teamMembers', { state, action });

    case 'CONTENT_FETCH_PAGE_COMPLETE':
      return handleContent('pages', { state, action });

    case 'CONTENT_FETCH_MENU_COMPLETE': {
      return handleContent('menus', {
        state,
        action,
        postProcessing: processMenu,
      });
    }

    case 'CONTENT_FETCH_BANNER_COMPLETE':
      return handleContent('banners', { state, action });

    case 'CONTENT_FETCH_SET_COMPLETE':
      return handleContent('sets', { state, action });

    case 'CONTENT_FETCH_CONFIG_COMPLETE':
      return handleContent('config', { state, action, segmentation: false });

    case 'CONTENT_FETCH_SECTION_COMPLETE':
      return handleContent('sections', { state, action });

    case 'CONTENT_FETCH_LANGUAGE_COMPLETE':
      return handleContent('languages', { state, action, segmentation: false });

    case 'CONTENT_FETCH_PROMOTION_COMPLETE':
      return handleContent('promotions', { state, action });

    case 'CONTENT_FETCH_PROMOTIONCATEGORY_COMPLETE':
      return handleContent('promotionCategories', { state, action });

    case 'CONTENT_FETCH_BLOGPOST_COMPLETE':
      return handleContent('blogPosts', { state, action });

    case 'CONTENT_FETCH_BLOGCATEGORY_COMPLETE':
      return handleContent('blogCategories', { state, action });

    case 'CONTENT_FETCH_BONUS_COMPLETE':
      return handleContent('bonuses', { state, action, segmentation: false });

    case 'CONTENT_FETCH_PAYMENT_COMPLETE':
      return handleContent('payments', { state, action });

    case 'CONTENT_FETCH_AFFILIATELANDINGPAGE_COMPLETE':
      return handleContent('affiliates', { state, action });

    case 'CONTENT_FETCH_TOURNAMENT_COMPLETE':
      return handleContent('tournaments', { state, action });

    case 'TRIGGER_SEGMENTATION': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
