import { combineEpics, ofType } from 'redux-observable';
import { getConfig } from '@config';
import userActions from 'tg-core-redux/lib/modules/devcode/action';
import walletActions from 'tg-core-redux/lib/modules/wallet/action';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import playerActions from 'tg-core-redux/lib/modules/player/action';
import {
  mergeMap,
  filter,
  map,
  ignoreElements,
  tap,
  delay,
} from 'rxjs/operators';

const reloadPaymentAccount = (action$, state$) =>
  action$.pipe(
    ofType('DELETE_USER_PAYMENT_ACCOUNT_COMPLETE'),
    mergeMap(() => {
      const { player, app } = state$.value;
      const MERCHANT_ID = getConfig(app.jurisdiction).MERCHANT_ID;

      return [
        userActions.getUserAvailablePaymentMethods({
          merchantId: MERCHANT_ID,
          userId: player.user.Id,
          sessionId: player.sessionId,
          method: 'Withdrawal',
        }),
        userActions.getUserAvailablePaymentMethods({
          merchantId: MERCHANT_ID,
          userId: player.user.Id,
          sessionId: player.sessionId,
          method: 'Deposit',
        }),
      ];
    })
  );

const reloadOngoingTransaction = (action$, state$) =>
  action$.pipe(
    ofType('CANCEL_USER_WITHDRAW_COMPLETE'),
    mergeMap(
      // re-fetching the balance after cancelled the withdraw
      () => {
        const { player, app } = state$.value;
        const MERCHANT_ID = getConfig(app.jurisdiction).MERCHANT_ID;
        // re-fetching the record of pending withdraws
        return [
          walletActions.getWallet(state$.value.player.sessionId),
          walletActions.getWallets(state$.value.player.sessionId),
          userActions.getUserOnGoingTransactions({
            merchantId: MERCHANT_ID,
            userId: player.user.Id,
            sessionId: player.sessionId,
            method: 'Withdrawal',
            states: 'PROCESSING,REPROCESSING,WAITING_APPROVAL,WAITING_INPUT',
          }),
        ];
      }
    )
  );

/**
 * Reload wallet, limits and payment stats after a successful deposit
 * Fetching with a delay to give backend time to update
 */
const reloadWalletAndLimitsAndPaymentStats = (action$, state$) =>
  action$.pipe(
    ofType('PAYMENT_SUCCESS', 'PAYMENT_PENDING', 'QUICK_DEPOSIT_SUCCESS'),
    delay(1000),
    mergeMap(() => [
      responsibleGamingActions.getLimit(state$.value.player.sessionId),
      walletActions.getWallet(state$.value.player.sessionId),
      walletActions.getWallets(state$.value.player.sessionId),
      playerActions.paymentStats(state$.value.player.sessionId),
    ])
  );

const redirectIfRefUrlIsPresent = (action$, state$) =>
  action$.pipe(
    ofType('DEPOSIT_COMPLETE', 'SIGNIN_PNP_COMPLETE'),
    tap(action => {
      const config = getConfig(
        state$.value.app.jurisdiction,
        state$.value.app.ipCountry
      );
      const refUrl =
        (action.payload && action.payload.refUrl) || action.payload.ref_url;
      const didClaimBonus = action.payload.didClaimBonus;
      const isSignup = action.payload.IsSignup;

      const claimedBonus = state$.value.bonus.data.find(
        bonus => bonus.Id == action.payload?.bonusCode
      );

      if (refUrl) {
        let redirect = decodeURIComponent(refUrl);

        if (redirect.charAt(0) === '/') {
          redirect = redirect.substring(1);
        }

        if (isSignup && config.signInRedirect) {
          config.signInRedirect;
        }

        window.routerHistory.replace({
          pathname: redirect,
          hash: 'popup/successful-deposit',
          state: { didClaimBonus, claimedBonus },
        });
      } else {
        if (action.payload.amount) {
          window.routerHistory.replace({
            pathname: 'casino',
            hash: 'popup/successful-deposit',
            state: { didClaimBonus, claimedBonus },
          });
        }
      }
    }),
    ignoreElements()
  );

const reloadPaymentStatsIfPnp = (action$, state$) =>
  action$.pipe(
    ofType('PAYMENT_SUCCESS', 'PAYMENT_PENDING'),
    filter(
      () =>
        getConfig(state$.value.app.jurisdiction, state$.value.app.ipCountry)
          .isPnp
    ),
    map(() => playerActions.paymentStats(state$.value.player.sessionId))
  );

const getDisplayPagesAfterPaymentSuccess = (action$, state$) =>
  action$.pipe(
    ofType('PAYMENT_SUCCESS', 'PAYMENT_PENDING'),
    map(() => playerActions.getDisplayPages(state$.value.player.sessionId))
  );

export default combineEpics(
  reloadPaymentAccount,
  reloadOngoingTransaction,
  reloadWalletAndLimitsAndPaymentStats,
  redirectIfRefUrlIsPresent,
  reloadPaymentStatsIfPnp,
  getDisplayPagesAfterPaymentSuccess
);
