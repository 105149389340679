import React from 'react';
import cn from 'classnames';
import Money from 'tg-core-components/lib/components/Money';
import Translate from 'tg-core-components/lib/components/Translate';
import Picture from 'tg-core-components/lib/components/Picture';
import MoneyBag from '@images/casino-jackpot-games.png';
import MultipleVideos from '@components/MultipleVideos';
import selectFeatureDetect from '@selectors/selectFeatureDetect';
import ImageRenderer from '@components/ImageRenderer';

export const selectGameThumbnailProps = state => ({
  featureDetect: selectFeatureDetect(state),
});

const GameThumbnail = ({
  game,
  isVertical,
  featureDetect,
  canShowVideoPreview,
  shouldShowPreview,
  type,
}) => {
  const thumbnailUrl = game?.thumbnail?.file?.url;
  const verticalThumbnailUrl = game?.verticalThumbnail?.file?.url;
  const shouldShowDealerThumbnail =
    game?.liveInfo?.ImageUrl && game?.liveInfo?.DealerImage;
  const thumbnailQuality =
    game?.quality || (game?.tags?.includes('big') ? 100 : 75);
  const ribbon = game?.badges?.find(b => b.includes('*'));
  const badgesFiltered = game?.badges?.filter(b => !b.includes('*'));
  const color = game.dominantColor;

  const previewFileType = game?.preview?.file?.contentType;
  const showVideo =
    (game?.videos?.length || previewFileType?.match(/^video\//)) &&
    canShowVideoPreview &&
    featureDetect.renderVideos;

  const allVideos = [game?.preview, ...(game?.videos || [])]
    .filter(el => typeof el !== 'undefined')
    .map(video => video && video.file);

  const srcURL = shouldShowDealerThumbnail
    ? game?.liveInfo?.ImageUrl
    : (verticalThumbnailUrl && type === 'live') || isVertical
    ? verticalThumbnailUrl
      ? verticalThumbnailUrl
      : thumbnailUrl
    : thumbnailUrl;

  return (
    <div className={cn('thumbnail', { live: game?.isLive })}>
      {game?.jackpot && (
        <div className="jackpot">
          <Picture className="money" src={MoneyBag} width={14} height={14} />
          <Money
            value={game?.jackpot?.Amount}
            currency={game?.jackpot?.Currency}
          />
        </div>
      )}
      {ribbon && (
        <div className="ribbon">
          <span>{ribbon.replace('*', '')}</span>
        </div>
      )}
      {badgesFiltered && (
        <div className="badges">
          {badgesFiltered.map((b, i) => (
            <span className="badge" key={i}>
              <Translate id={`label.gamebadge.${b}`} defaultMessage={b} />
            </span>
          ))}
        </div>
      )}

      <ImageRenderer
        classes={cn({
          hide: shouldShowPreview,
        })}
        color={color}
        src={srcURL}
        thumbnailQuality={thumbnailQuality}
        width="250"
        height="138"
      />

      {shouldShowPreview && (
        <>
          {showVideo ? (
            <MultipleVideos
              className="video-preview"
              videos={allVideos}
              muted
              loop
              playsInline
              autoPlay
              preload="none"
            />
          ) : (
            <ImageRenderer
              classes="Game__image-preview"
              color={color}
              src={game?.preview.file.url}
              alt="preview"
              thumbnailQuality={thumbnailQuality}
              width="250"
              height="138"
            />
          )}
        </>
      )}
    </div>
  );
};

export default GameThumbnail;
