import React, { forwardRef, useEffect, useState } from 'react';

const GameSkeleton = forwardRef(function GameSkeleton({ color, opacity }, ref) {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (opacity === 0) {
      setHide(true);
    }
  }, [opacity]);

  return hide ? null : (
    <div
      ref={ref}
      style={{ backgroundColor: color, opacity }}
      className="Game Game--skeleton thumbnail--skeleton shine">
      <div className="game-info--skeleton">
        <span className="shine"></span>
        <span className="shine"></span>
      </div>
    </div>
  );
});

export default GameSkeleton;
