import { combineEpics, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { isFavorite } from '../utils/game';
import {
  getFavorites,
  setFavorite,
  removeFavorite,
} from 'tg-core-redux/lib/modules/casino/action';
import { zip } from 'rxjs';

const handleToggleFavorite = (action$, state$) =>
  action$.pipe(
    ofType('TOGGLE_FAVORITE_GAME'),
    filter(() => state$.value.player.isAuthenticated),
    map(action => {
      const { player, favorite } = state$.value;
      const { gameId, tableId } = action.payload;

      return isFavorite(favorite.games, gameId, tableId)
        ? removeFavorite(player.sessionId, gameId, tableId)
        : setFavorite(player.sessionId, gameId, tableId);
    })
  );

const migrateFavoritesAfterLogin = (action$, state$) =>
  zip(
    action$.ofType('SIGNINV2_COMPLETE', 'SIGNINWITHCODE_COMPLETE'),
    action$.ofType('GET_FAVORITES_COMPLETE')
  ).pipe(
    map(() => {
      const { player, favorite, games } = state$.value;

      const localFavoriteGames = JSON.parse(
        localStorage.getItem('favorite_games')
      );

      if (localFavoriteGames) {
        // Extract favorite games from state since these contain table ID.
        const mappedLocalFavoriteGames = games.data.filter(g =>
          localFavoriteGames.games?.some(
            f =>
              f.backendId === g.backendId && f.gameIdentifier === g.identifier
          )
        );

        // Filter out games not added as favorite.
        const favoriteGamesToAdd = mappedLocalFavoriteGames.filter(
          g => !isFavorite(favorite.games, g.backendId, g.tableId)
        );

        localStorage.removeItem('favorite_games');

        return favoriteGamesToAdd.map(g =>
          setFavorite(player.sessionId, g.backendId, g.tableId)
        );
      }

      return [];
    })
  );

const getFavoritesAfterToggle = (action$, state$) =>
  action$.pipe(
    ofType('SET_FAVORITE_COMPLETE', 'REMOVE_FAVORITE_COMPLETE'),
    filter(() => state$.value.player.isAuthenticated),
    map(() => {
      const { player } = state$.value;

      return getFavorites(player.sessionId);
    })
  );

export default combineEpics(
  handleToggleFavorite,
  getFavoritesAfterToggle,
  migrateFavoritesAfterLogin
);
