import Fuse from 'fuse.js';

export const getFuseSearch = games =>
  new Fuse(games, {
    keys: [
      { name: 'name', weight: 2 },
      'provider',
      'subprovider',
      'tags',
      'categories.name',
    ],
    threshold: 0.4,
    minMatchCharLength: 1,
  });

/**
 * Map sections/local games with centralized games
 * if a game is found we use it, if not we fallback to item which we expect is a game.
 * @param {array} array of games
 * @returns {item => {game}} function that tries to map a game or section to centralized.
 */
export const mapGame = games => item => {
  if (item.backendId) return item;

  return games.find(g => g?.identifier === item?.config?.externalIdentifier);
};

export const getCustomCategoryGames = (items, games) => {
  return items.map(mapGame(games)).filter(Boolean);
};

export const getGamesByCategory = (
  games,
  category,
  lastPlayedGames,
  favoriteGames,
  config,
  topGames
) => {
  switch (category) {
    case null:
    case 'all-games':
      return games;
    case 'last-played-games':
      return lastPlayedGames;
    case 'my-games':
      return favoriteGames;
    case 'top-games':
      return getTopGames(config, topGames);
    default:
      return games?.filter(g => g.categories?.includes(category));
  }
};

export const getCategoriesBySearchTerm = (categories, searchTerm) => {
  const reg = new RegExp(
    (searchTerm || '').replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'),
    'i'
  );
  return categories?.items.filter(c => reg.test(c.name)) || [];
};

export const getNavbarItems = ({
  navbar,
  hasLastPlayedGames,
  favoriteGames,
}) => {
  return (
    navbar?.items
      .filter(c =>
        c.identifier === 'my-games' ? favoriteGames.length > 0 : true
      )
      .filter(c =>
        c.identifier === 'last-played-games' ? hasLastPlayedGames : true
      ) || []
  );
};

export const CATEGORY_TYPES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  VERTICAL: 'vertical',
  LIST: 'top-list',
  DOUBLE: 'double-rows',
};

export const getCategoryType = categoryType => {
  const category = categoryType ? categoryType.toLowerCase() : '';

  switch (category) {
    case 'large':
      return CATEGORY_TYPES.LARGE;
    case 'vertical':
      return CATEGORY_TYPES.VERTICAL;
    case 'list':
      return CATEGORY_TYPES.LIST;
    case 'double':
      return CATEGORY_TYPES.DOUBLE;
    default:
      return CATEGORY_TYPES.MEDIUM;
  }
};

const getTopGames = (config, topGames) => {
  const limit = config?.limit || 10;

  switch (config?.sortBy) {
    case 'topGames':
      return topGames?.popular.slice(0, limit);
    case 'topGamesWager':
      return topGames?.wager.slice(0, limit);
    case 'topGamesWagerUWC':
      return topGames?.wagerUWC.slice(0, limit);
    default:
      return topGames?.popular.slice(0, limit);
  }
};
