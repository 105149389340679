import { pushGtmEvent } from '@actions/app';
import paynplayActions from '@actions/paynplay';
import selectCurrency from '@selectors/selectCurrency';
import { useConfig } from '@utils/config';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import bonusActions from 'tg-core-redux/lib/modules/bonus/action';
import responsibleGamingActions from 'tg-core-redux/lib/modules/responsible_gaming/action';
import {
  getInitialStep,
  getNextStep,
  getRequiredSteps,
  getSteps,
  getStepStatuses,
} from './helpers';
import { Error } from './steps';
import selectCountries from '@selectors/selectCountries';
import cn from 'classnames';
import { selectBonuses } from '@components/BonusSelector';

import './style.css';

const PayNPlay = ({ fetchBalance, onClose, location, isInline }) => {
  const config = useConfig();
  const cookies = new Cookies();
  const contentfulConfig = useSelector(state => state.content.config.data);
  const paymentTransactions = useSelector(state => state.paymentTransactions);
  const isAuthenticated = useSelector(state => state.player.isAuthenticated);
  const currency = useSelector(state => selectCurrency(state));
  const amountLimits = contentfulConfig?.find(c => c.key === 'amountLimits');
  const prefilledAmount = contentfulConfig?.find(
    c => c.key === 'prefilledAmount'
  );
  const params = queryString.parse(location.search);
  const countries = useSelector(state => selectCountries(state));
  const bonuses = useSelector(state => selectBonuses(state));
  const restrictedAccess = useSelector(
    state => state.content.sections.data
  )?.find(i => i.identifier === 'popup-restricted-access');

  const { depositAmount, bonusCode, ref_url, pendingOptIn } = params;

  const pendingWithdrawal = paymentTransactions?.data
    ?.filter(transaction => transaction.Status === 'initiated')
    ?.sort((a, b) => b.Amount - a.Amount)[0];

  const jurisdiction = useSelector(state => state.app.jurisdiction);
  const ipCountry = useSelector(state => state.app.ipCountry);

  const country =
    countries?.length === 1
      ? countries?.[0]
      : countries?.find(c => c.value === ipCountry) || countries?.[0];

  const minDepositAmount =
    amountLimits.value.min[currency] || amountLimits.value.min.EUR;

  const maxDepositAmount =
    amountLimits.value.max[currency] || amountLimits.value.max.EUR;

  const [steps, setSteps] = useState(
    getSteps(jurisdiction, isAuthenticated, config)
  );
  const [data, setData] = useState(null);
  const [depositSuccess, setDepositSuccess] = useState(false);

  // Needed since nextStep would be outdated when used inside an event listener.
  useEffect(() => {
    if (depositSuccess) {
      nextStep();
    }
  }, [depositSuccess]);

  const sessionId =
    useSelector(state => state.player.sessionId) || data?.sessionId;

  const initialStep = getInitialStep(
    config,
    steps,
    depositAmount,
    sessionId,
    fetchBalance,
    pendingWithdrawal,
    isInline
  );

  const [prevStep, setPrevStep] = useState(null);
  const [step, setStep] = useState(initialStep);

  const [fields, setFields] = useState({
    Limits:
      jurisdiction === 'sga'
        ? [
            { Type: 'Deposit', Amount: 99999999, Timespan: '1' },
            { Type: 'Deposit', Amount: 99999999, Timespan: '7' },
            { Type: 'Deposit', Amount: 99999999, Timespan: '30' },
          ]
        : null,
    MobilePhoneNumber: country.callingCode && `00${country.callingCode}`,
    Amount: !fetchBalance
      ? depositAmount || prefilledAmount?.value?.[currency]
      : '',
    BonusCode: bonusCode,
  });
  const [errors, setErrors] = useState({});
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const intl = useIntl();
  const dispatch = useDispatch();

  const onSuccess = async data => {
    setData(data);
  };

  const onError = err => {
    dispatch(pushGtmEvent({ event: GTM_EVENTS.PAYNPLAY_FAILURE }));

    setErrors({ Error: err });
    nextStep(Error);
  };

  const handleFieldChanges = async fields => {
    const validate = step.validate ? step.validate : () => Promise.resolve();
    const stepFields = step.fields || [];

    let stepStatuses = getStepStatuses(stepFields, fields, errors);

    setIsLoading(true);

    const result = await validate(
      fields,
      jurisdiction,
      minDepositAmount,
      maxDepositAmount
    );

    if (!result) return;

    stepStatuses = getStepStatuses(stepFields, fields, result.errors);

    setIsLoading(false);
    setErrors(result.errors);
    setStatuses(stepStatuses);
  };

  const getDataAfterLogin = async () => {
    if (!sessionId) return;

    const [limits] = await Promise.all([
      dispatch(responsibleGamingActions.getLimit(sessionId)),
      dispatch(bonusActions.getBonuses(sessionId, config.bonusProvider)),
    ]);

    setFields({
      ...fields,
      Limits: limits?.data,
    });
  };

  // Get data after login
  useEffect(() => {
    getDataAfterLogin();
  }, [sessionId]); // eslint-disable-line

  // After auth when limits from request has been set, go to next step.
  useEffect(() => {
    if (step.name === 'auth' && sessionId) {
      setSteps(
        getRequiredSteps(
          steps,
          fields,
          data,
          config.communicationConsent?.enabled,
          config.onlyShowBonusOnSignUp
        )
      );
    }
  }, [fields.Limits]); // eslint-disable-line

  useEffect(() => {
    if (step.name === 'auth' && sessionId) {
      nextStep();
    }
  }, [steps]); // eslint-disable-line

  // Handle init and close GTM events.
  useEffect(() => {
    dispatch(pushGtmEvent({ event: GTM_EVENTS.PAYNPLAY_INIT }));

    return () => dispatch(pushGtmEvent({ event: GTM_EVENTS.PAYNPLAY_CLOSE }));
  }, [dispatch]);

  // Validate fields as user types.
  useEffect(() => {
    handleFieldChanges(fields);
  }, [fields]); // eslint-disable-line

  // Push GTM event on step change, reset validation state and scroll to top.
  useEffect(() => {
    dispatch(pushGtmEvent({ event: `paynplay ${step.name}` }));

    window.scrollTo(0, 0);
  }, [step, dispatch]); // eslint-disable-line

  const refUrl = __CLIENT__
    ? encodeURI(location.pathname + location.search + location.hash)
    : null;

  const onMessage = message => {
    if (
      message.data.name === 'pnp-deposit-success' ||
      message.data.name === 'pnp-deposit-pending'
    ) {
      setData(message.data.payload);
      setDepositSuccess(true);
    }

    if (message.data.name === 'pnp-deposit-failure') {
      window.location = refUrl ? refUrl : '/casino';
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []); // eslint-disable-line

  const nextStep = nextStep => {
    setPrevStep(step);

    if (nextStep?.Component) {
      return setStep(nextStep);
    }

    if (steps.indexOf(step) === steps.length - 1) {
      dispatch(pushGtmEvent({ event: GTM_EVENTS.PAYNPLAY_SUCCESS }));

      const BonusCode = nextStep?.BonusCode || fields?.BonusCode;
      const didClaimBonus = Boolean(BonusCode);

      if (isAuthenticated) {
        return window.handleDepositComplete(
          window.location.search,
          BonusCode,
          didClaimBonus
        );
      }

      return dispatch(
        paynplayActions.success({
          ...data,
          SessionId: data.sessionId,
          didClaimBonus: didClaimBonus,
          bonusCode: BonusCode,
          amount: fields.Amount,
        })
      );
    }

    setStep(getNextStep(steps, step, prevStep, fields));
  };

  const previousStep = () => {
    setStep(prevStep);
  };

  const Step = step.Component;

  const selector = useSelector(state =>
    step.selector ? step.selector(state) : {}
  );

  const disableRestrictedAccess =
    restrictedAccess && cookies.get('disableRestrictedAccess');

  if (restrictedAccess && !disableRestrictedAccess && params.dra) return null;

  return (
    <div className={cn('PayNPlay', step.name)}>
      <Step
        {...selector}
        {...data}
        isInline={isInline}
        refUrl={ref_url}
        pendingOptIn={pendingOptIn}
        sessionId={sessionId}
        pendingWithdrawal={pendingWithdrawal}
        minDepositAmount={minDepositAmount}
        maxDepositAmount={maxDepositAmount}
        bonuses={bonuses}
        setIsLoading={setIsLoading}
        errors={errors}
        statuses={statuses}
        isLoading={isLoading}
        intl={intl}
        fields={fields}
        nextStep={nextStep}
        previousStep={previousStep}
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
        onChange={field =>
          setFields({
            ...fields,
            ...field,
          })
        }
      />
    </div>
  );
};

export default withRouter(PayNPlay);
