import { ofType, combineEpics } from 'redux-observable';
import { filter, switchMap, take, map } from 'rxjs/operators';
import { timer } from 'rxjs';
import eventActions from 'tg-core-redux/lib/modules/event/action';
import selectCurrency from '@selectors/selectCurrency';

const ONE_MINUTE_IN_MS = 1000 * 60;
const FIVE_MINUTES_IN_MS = 5000 * 60;

const initiateLiveCasinoDataPolling = (action$, state$) =>
  action$.pipe(
    ofType('TRIGGER_SEGMENTATION'),
    switchMap(() =>
      action$.pipe(
        filter(() => state$.value.games.data),
        take(1),
        switchMap(() =>
          timer(0, ONE_MINUTE_IN_MS * 1).pipe(
            filter(() => window.location.pathname.includes('casino')),
            filter(
              () =>
                state$.value.games.data.filter(
                  g =>
                    g.tableId &&
                    g.tags?.map(t => t.toLowerCase()).includes('live')
                ).length > 0
            ),
            map(() => {
              const games = state$.value.games.data.filter(
                g =>
                  g.tableId &&
                  g.tags?.map(t => t.toLowerCase()).includes('live')
              );

              let tableIds = games.map(g => g.tableId);
              tableIds = [...new Set(tableIds)].toString();
              let gameIds = games.map(g => g.backendId);
              gameIds = [...new Set(gameIds)].toString();

              return eventActions.getLiveCasinoV2({
                tableIds,
                gameIds,
              });
            })
          )
        )
      )
    )
  );

const initiateJackpotsDataPolling = (action$, state$) =>
  action$.pipe(
    ofType('BOOTSTRAP_DONE'),
    switchMap(() =>
      timer(0, FIVE_MINUTES_IN_MS * 1).pipe(
        map(() => eventActions.getJackpots(selectCurrency(state$.value)))
      )
    )
  );

export default combineEpics(
  initiateLiveCasinoDataPolling,
  initiateJackpotsDataPolling
);
