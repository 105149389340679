import React, { useState, useEffect, useRef } from 'react';
import GameSkeleton from '@components/Game/GameSkeleton';
import Picture from 'tg-core-components/lib/components/Picture';
import { FORMAT, createImageUrl } from '@utils/picture';

const ImageRenderer = ({
  src,
  thumbnailQuality,
  color,
  classes,
  width,
  height,
}) => {
  const [inView, setInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const skeletonRef = useRef();

  useEffect(() => {
    const options = {
      rootMargin: '100px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setInView(true);
          obs.disconnect();
        }
      }
    }, options);

    if (skeletonRef.current) {
      observer.observe(skeletonRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return inView ? (
    <>
      <GameSkeleton
        ref={skeletonRef}
        color={color}
        opacity={isLoaded ? 0 : 1}
      />
      <Picture
        className={classes}
        src={[
          {
            src: createImageUrl({
              src: src,
              format: FORMAT.WEBP,
              quality: thumbnailQuality,
              progressive: true,
            }),
            type: FORMAT.WEBP,
          },
          {
            src: createImageUrl({
              src: src,
              format: FORMAT.JPG,
              quality: thumbnailQuality,
              progressive: true,
            }),
            type: FORMAT.JPG,
          },
        ]}
        load={() => setIsLoaded(true)}
        width={width}
        height={height}
        opacity={isLoaded ? 1 : 0}
      />
    </>
  ) : (
    <GameSkeleton color={color} ref={skeletonRef} />
  );
};

export default ImageRenderer;
