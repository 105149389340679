import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Translate, Icon } from 'tg-core-components';
import NoGamesMessage from '@components/NoGamesMessage';
import GameGroupTitle from '@components/GameGroupTitle';
import GameGrid from '@components/GameGrid';

const GridContainer = ({
  isSearching,
  match,
  categoryItems,
  country,
  baseUrl,
  searchResult,
  gameGridGames,
  toggleFavoriteGameById,
  categoryFromParams,
  closeSearch,
  onClickLink,
  gameGridProps,
  waypoint,
}) => {
  return (
    <div className="GridContainer">
      <div className="GridContainer__go-back">
        <Link to={baseUrl}>
          <Icon icon="chevron-left" />
          <Translate id="label.go-back" defaultMessage="Go back" />
        </Link>
      </div>

      {!isSearching && (
        <div className="grid-header">
          <GameGroupTitle
            country={country}
            identifier={match.params.category}
            icon={
              categoryItems?.items?.find(
                c => c.identifier === match.params.category
              )?.icon
            }
          />
        </div>
      )}
      {(searchResult || gameGridGames || []).length > 0 ? (
        <GameGrid
          className={cn('GameGrid--extra-margin-on-mobile', {
            'gamegrid-extra-margin-top': isSearching,
          })}
          items={searchResult || gameGridGames}
          toggleFavoriteGameById={toggleFavoriteGameById}
          category={categoryFromParams}
          closeSearch={() => closeSearch()}
          {...gameGridProps}
        />
      ) : (
        <div className="no-games">
          <NoGamesMessage
            linkLocation={baseUrl}
            onClickLink={() => onClickLink()}
          />
        </div>
      )}

      {waypoint}
      {waypoint && (
        <div className="loading-games h3">
          <Translate id="message.loading" defaultMessage="Loading..." />
        </div>
      )}
    </div>
  );
};

export default GridContainer;
