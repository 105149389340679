import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import axios from 'axios';
import getByPath from 'lodash/get';
import { Translate } from 'tg-core-components';
import {
  SupportNumber,
  Email,
  EmailSE,
  Password,
  Phone,
  Gender,
  Name,
  NameES,
  BirthDate,
  Country,
  Location,
  Currency,
  Limits,
  Confirm,
  Activation,
  Welcome,
  PersonalIdSE,
  PersonalIdES,
  ExternalAuthentication,
  Nationality,
  PasswordES,
  Verify,
  Deposit,
  City,
  SignUpBlocked,
  AnnualActivity,
} from './steps';

export const getSteps = (
  jurisdiction,
  jwtToken,
  config,
  overrideCountry,
  hideCurrencyStep
) => {
  if (config?.block?.signUp) {
    return [SignUpBlocked];
  }

  if (config?.quickRegistration) {
    return [
      Email,
      ...(config.includePhoneStep ? [Phone] : []),
      Password,
      Name,
      BirthDate,
      ...(!hideCurrencyStep ? [Currency] : []),
      Confirm,
      Deposit,
      Welcome,
    ];
  }

  switch (jurisdiction?.toLowerCase()) {
    case 'sga':
      return [
        ...(!jwtToken ? [PersonalIdSE, ExternalAuthentication] : []),
        EmailSE,
        Phone,
        ...(!config?.hideLimitStep ? [Limits] : []),
        Confirm,
        Deposit,
        Welcome,
      ];

    case 'es':
      return [
        Email,
        Phone,
        Nationality,
        PersonalIdES,
        SupportNumber,
        Gender,
        NameES,
        ...(overrideCountry ? [] : [Country]),
        City,
        Location,
        BirthDate,
        PasswordES,
        Currency,
        Confirm,
        Activation,
        Verify,
        Deposit,
        Welcome,
      ];

    default:
      return [
        Email,
        Password,
        Phone,
        Gender,
        Name,
        ...(overrideCountry ? [] : [Country]),
        City,
        Location,
        BirthDate,
        Currency,
        ...(!config?.hideLimitStep ? [Limits] : []),
        AnnualActivity,
        Confirm,
        ...(!(config?.disableActivation || config?.disableActivationStep)
          ? [Activation]
          : []),
        Deposit,
        Welcome,
      ];
  }
};

export const getContinueLabel = device => {
  return device?.toLowerCase() === 'desktop' ? (
    <Translate id="action.button.press-enter" defaultMessage="Press enter" />
  ) : (
    <Translate id="action.button.continue" defaultMessage="Continue" />
  );
};

export const useAddressSuggestions = (address, city, url) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSuggestions = async (address, city) => {
    try {
      setIsLoading(true);

      const res = await axios.get(url + '/autocomplete', {
        params: {
          query: address,
          types: 'address',
          city,
        },
      });

      setIsLoading(false);
      setSuggestions(res.data);
    } catch {
      setIsLoading(false);
    }
  };

  const getSuggestionsDebounced = useCallback(
    debounce(getSuggestions, 500),
    []
  );

  useEffect(() => {
    const trimmedAddress = address?.trim();

    if (trimmedAddress !== '') {
      getSuggestionsDebounced(trimmedAddress, city);
    }
  }, [address, city, getSuggestionsDebounced]);

  return {
    suggestions,
    isLoading,
  };
};

export const getStepStatuses = (steps, fields, errors) =>
  steps
    .map(step => ({
      [step]:
        fields[step] === undefined
          ? 'idle'
          : errors[step]
          ? 'failure'
          : 'success',
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

let cache = {};

export const apiCache = endpoint => (...args) => {
  const endpointName = endpoint
    .toString()
    .match(/function(.*?)\(/)[1]
    .trim();

  const cacheKey = `${endpointName}_${args.join('_')}`;
  const cachedResponse = cache[cacheKey];

  if (cachedResponse) return Promise.resolve(cachedResponse);

  return endpoint(...args)
    .then(res => {
      cache = {
        ...cache,
        [cacheKey]: res,
      };

      return res;
    })
    .catch(err => {
      // Don't cache rate limited requests or network errors.
      if (
        err?.value?.response?.data?.Code !== 'RateLimited' &&
        err?.value?.response
      ) {
        cache = {
          ...cache,
          [cacheKey]: err,
        };
      }

      return err;
    });
};

export const createSectionId = error =>
  ['sign-up-items-error', getByPath(error, 'Code')]
    .filter(p => Boolean(p))
    .join('-')
    .toLowerCase();
