const initialState = {
  data: [],
  isLoading: false,
};

const uniqueGames = (prevgames, newgames) => {
  const map = new Map(
    [...prevgames, ...newgames].map(item => [
      item.backendId + '-' + item.tableId,
      item,
    ])
  );
  return [...map].map(item => item[1]);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_GAMES_FAILURE':
      return {
        ...state,
        isLoading: false,
      };

    case 'FETCH_GAMES_START':
      return {
        ...state,
        isLoading: true,
      };

    case 'FETCH_GAMES_COMPLETE':
      return {
        ...state,
        isLoading: false,
        data: uniqueGames(state.data, action.response),
      };

    case 'SEGMENT_GAMES':
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};
