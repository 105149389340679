import React, { Component } from 'react';
import { Loader as TGCoreLoader } from 'tg-core-components';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { showGlobalLoader, hideGlobalLoader } from '@actions/app';

export const Loader = ({ isLoading, children }) => {
  if (isLoading) return <TGCoreLoader inline />;
  return children;
};

const withRouteData = BaseComponent => {
  @connect(null, {
    showGlobalLoader,
    hideGlobalLoader,
  })
  class WithRouteData extends Component {
    constructor(props) {
      super(props);
      this.showGlobalLoader =
        typeof BaseComponent.globalLoader === 'undefined' ||
        BaseComponent.globalLoader;
      this.url = BaseComponent.hashRoute
        ? props.overlayLocation.hash
        : props.location.pathname;
      this.manualDataLoader = BaseComponent.manualDataLoader;

      this.state = {
        ready: __CLIENT__ ? global.__LOAD_STATE__[this.url] : false,
      };
    }

    componentDidMount() {
      if (this.manualDataLoader || global.__LOAD_STATE__[this.url]) return;
      this.loadData(BaseComponent.hashRoute);
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.overlayLocation?.hash != prevProps.overlayLocation?.hash ||
        this.props.location?.pathname != prevProps.location?.pathname
      ) {
        this.url = BaseComponent.hashRoute
          ? this.props.overlayLocation.hash
          : this.props.location.pathname;

        if (this.manualDataLoader || global.__LOAD_STATE__[this.url]) return;
        this.loadData(BaseComponent.hashRoute);
      }
    }

    loadData = hashRoute => {
      const { loadData, showGlobalLoader, hideGlobalLoader } = this.props;
      this.showGlobalLoader && showGlobalLoader();
      this.setState({ ready: false });
      loadData(hashRoute).then(() => {
        this.setState({
          ready: true,
        });
        hideGlobalLoader();
      });
    };

    render() {
      const isLoading = __CLIENT__ && !this.state.ready;

      if (isLoading && this.showGlobalLoader) return null;

      return (
        <BaseComponent
          {...this.props}
          manualDataLoader={this.loadData}
          isRouteDataLoading={isLoading}
        />
      );
    }
  }

  return hoistNonReactStatic(WithRouteData, BaseComponent);
};

export default withRouteData;
