import { isFavorite, isTopGame } from '@utils/game';

const selectGames = state => {
  return state.games.data
    ?.map(game => {
      game.isLive = game.tags?.map(t => t.toLowerCase()).includes('live');

      game.liveInfo = state.event.livecasino?.find(
        g => g.GameId === game.backendId && g.ExternalTableId === game.tableId
      );

      game.isFavorite = isFavorite(
        state.favorite.games,
        game.backendId,
        game.tableId
      );

      game.jackpot = state.event.jackpots?.find(
        j => j.GameIds?.includes(game.backendId) || j.Name === game.jackpotName
      );

      return game;
    })
    .sort((a, b) =>
      b.sortOrder == undefined ? -1 : b.sortOrder - a.sortOrder
    );
};

export default selectGames;
