import queryString from 'query-string';
import http from '@utils/http';
import { getConfig } from '@config';
import { getContentPreviewSettings } from './contentPreview';

/**
 * Reduce function to orginaze big
 * game thumbs in the top of array.
 */
export const gameOrganizeBig = (items, max = 1) => {
  const biggames = [];

  const games = items.reduce((_items, game) => {
    if (biggames.length < max && game.tags && game.tags.includes('big')) {
      if (game.tags.indexOf('big_thumb') < 0) game.tags.push('big_thumb');

      biggames.push(game);
    } else {
      _items.push(game);
    }

    return _items;
  }, []);

  return [...biggames, ...games];
};

export const fetchContent = (
  content_type,
  locale,
  { include, identifiers, segment, fields, urls, ...otherParams } = {},
  config = getConfig().content,
  cookies
) => {
  const contentPreviewSettings = getContentPreviewSettings(null, cookies);
  const isPreviewMode =
    contentPreviewSettings && contentPreviewSettings.previewMode;

  const queryParams = {
    access_token: isPreviewMode
      ? config.preview_access_token
      : config.access_token,
    content_type: content_type,
    locale: locale,
    include: include || 2,
    ...otherParams,
  };

  // filter by ids
  if (identifiers) {
    queryParams['fields.identifier[in]'] = identifiers.join(',');
  }

  // filter by segment
  if (segment) {
    queryParams['fields.segment.sys.id[in]'] = segment.join(',');
  }

  if (Array.isArray(urls)) {
    queryParams['fields.url[in]'] = urls.join(',');
  }

  if (typeof urls === 'string') {
    queryParams['fields.url[match]'] = urls;
  }

  if (fields) {
    const fieldQuery = fields.map(fieldName => `fields.${fieldName}`).join(',');

    queryParams['select'] = `sys.id,${fieldQuery}`;
  }

  const query = queryString.stringify(queryParams);
  const environment = config.environment;

  const url = `${isPreviewMode ? config.preview_host : config.host}/spaces/${
    config.space
  }/environments/${environment}/entries?${query}`;

  return http.get(url);
};
