import { useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { openHash } from '@utils/hash';
import { segmentGame } from '@actions/games';

export const useSetLastPlayedGame = ({
  sessionId,
  game,
  setLastPlayedGame,
  isGameLaunched,
}) => {
  const isPending = useRef(false);

  useEffect(() => {
    if (sessionId && !isPending.current && isGameLaunched) {
      isPending.current = true;
      setLastPlayedGame(sessionId, game.backendId).then(
        () => (isPending.current = false)
      );
    }
  }, [game, sessionId, setLastPlayedGame, isGameLaunched]);
};

/**
 * Show a popup when play session expires
 */
export const usePlaySessionLimit = ({ game, history }) => {
  const playSessionLimitAmount = useSelector(state =>
    state.responsible_gaming.data?.find(i => i.Type === 'Session')
  )?.Amount;
  const playSessionExpiresTimestamp = useMemo(
    () =>
      playSessionLimitAmount && game.identifier
        ? Date.now() + playSessionLimitAmount * 60 * 1000
        : null,
    [game.identifier, playSessionLimitAmount]
  );
  useEffect(() => {
    const intervalFn = () => {
      const isPlaySessionPopupOpen = history.location.hash.includes(
        'popup/play-session-expired'
      );
      if (!isPlaySessionPopupOpen && Date.now() > playSessionExpiresTimestamp) {
        history.push({
          hash: openHash(history.location.hash, 'popup/play-session-expired'),
        });
      }
    };
    const interval = playSessionExpiresTimestamp
      ? setInterval(intervalFn, 15 * 1000)
      : null;
    return () => clearInterval(interval);
  }, [history, playSessionExpiresTimestamp]);
};

export const isGameDisabled = ({
  game,
  userCountry,
  ipCountry,
  isAuthenticated,
  userZip,
  cmsConfig,
}) => {
  const rules = cmsConfig?.find(c => c.key === 'countries').value;

  return !segmentGame(
    game,
    userCountry,
    ipCountry,
    isAuthenticated,
    userZip,
    rules
  );
};
