import React from 'react';
import CasinoPromotionSkeleton from '@components/CasinoPromotion/CasinoPromotionSkeleton';
import GameSkeleton from '@components/Game/GameSkeleton';

import './style.css';

export const SkeletonRow = ({ items }) => (
  <div className="Skeleton row">
    <div className="Skeleton__header">
      <span className="shine"></span>
      <span className="shine"></span>
    </div>
    <div className="Skeleton__grid">
      {[...Array(items)].map((_, i) => (
        <GameSkeleton
          key={`row-${i}`}
          classNames="Skeleton__grid__game shine"
        />
      ))}
    </div>
  </div>
);

const CasinoSkeletonLayout = () => (
  <div className="CasinoSkeletonLayout">
    <SkeletonRow items={8} />
    <SkeletonRow items={8} />
    <CasinoPromotionSkeleton />
    <SkeletonRow items={8} />
    <SkeletonRow items={8} />
    <CasinoPromotionSkeleton />
    <SkeletonRow items={8} />
    <SkeletonRow items={8} />
  </div>
);

export default CasinoSkeletonLayout;
