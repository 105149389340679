import { combineEpics } from 'redux-observable';
import playerEpics from './player';
import alertEpics from './alert';
import contentEpics from './content';
import responsibleGamingEpics from './responsible_gaming';
import errorEpics from './error';
import kycEpics from './kyc';
import newsletterEpics from './newsletter';
import eventEpics from './event';
import bonusEpics from './bonus';
import paymentEpics from './payment';
import gtmEpics from './gtm';
import quickDepositEpics from './quickDeposit';
import experimentEpics from './experiment';
import internalMessageEpics from './internalmessage';
import requiredActionsEpics from './requiredActions';
import favoriteEpic from './favorite';
import casinoEpic from './casino';
import gameEpic from './games';

export const rootEpic = combineEpics(
  playerEpics,
  alertEpics,
  contentEpics,
  responsibleGamingEpics,
  errorEpics,
  kycEpics,
  newsletterEpics,
  eventEpics,
  bonusEpics,
  paymentEpics,
  gtmEpics,
  quickDepositEpics,
  internalMessageEpics,
  requiredActionsEpics,
  experimentEpics,
  favoriteEpic,
  casinoEpic,
  gameEpic
);
