import React, { Component } from 'react';
import { connect } from 'react-redux';
import marked from 'marked';
import withRouteData from '@utils/withRouteData';
import BasePage from '@pages/BasePage';
import NavList from '@components/NavList';
import Loader from '@components/Loader';
import PageBuilder from '@components/PageBuilder';
import cn from 'classnames';

import { fetchContent, filterOutContentBySegmentation } from '@actions/content';

import './style.css';

@withRouteData
@connect(state => ({
  pages: state.content.pages.data,
  menus: state.content.menus.data,
  locale: state.app.locale,
  games: state.games.data,
  device: state.app.device,
  jackpots: state.event.jackpots,
}))
class ContentContainer extends Component {
  state = {
    isLoading: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = nextProps;

    if (this.props.match.url !== match.url) {
      const { dispatch, locale } = this.props;

      this.setState({ isLoading: true });
      dispatch(
        fetchContent('page', locale, {
          'fields.url[in]': match.url.substr(1),
          include: 5,
        })
      ).then(() => {
        this.setState({ isLoading: false });
        dispatch(filterOutContentBySegmentation());
      });
    }
  }

  render() {
    const { pages, menus, match, games, device, jackpots } = this.props;
    const page = pages?.find(p => '/' + p.url === match.url);
    const error = pages?.find(p => p.identifier === 'error-page');
    const pageSections = page?.sections || [];

    const totalJackpot = jackpots?.reduce(
      (prev, curr) => prev + curr.Amount,
      0
    );
    const currency = jackpots?.[0]?.Currency;

    if (this.state.isLoading) return <Loader />;

    const menu =
      menus?.find(m => m.identifier === page?.config?.navBarIdentifier) ||
      menus?.find(m => m.identifier === 'side-content-menu');
    const hideSidebar = page?.layout?.includes('No sidebar');
    const hideHeader = page?.layout?.includes('No header');

    if (!page) return <BasePage page={undefined} error={error} />;

    return (
      <BasePage
        className={cn('ContentContainer', page?.identifier)}
        page={page}
        hideHeader={hideHeader}>
        {page && (
          <main className="container">
            <div className="content-container">
              <div className="content">
                {!hideSidebar && menu && <NavList menu={menu} />}
                <div className="content-wrapper">
                  <div
                    className="actual-content"
                    dangerouslySetInnerHTML={{
                      __html: marked(page.content || ''),
                    }}
                  />

                  <PageBuilder
                    items={pageSections}
                    games={games}
                    totalJackpot={totalJackpot}
                    currency={currency}
                    device={device}
                  />
                </div>
              </div>
            </div>
          </main>
        )}
      </BasePage>
    );
  }
}

export default ContentContainer;
