import React, { Component } from 'react';
import marked from 'marked';
import { connect } from 'react-redux';
import Promotion from './Promotion';
import PageBuilder from '@components/PageBuilder';
import CasinoCategoryMenu from '@components/CasinoCategoryMenu';

import BasePage from '@pages/BasePage';
import withRouteData from '@utils/withRouteData';

import './style.css';

@withRouteData
@connect(state => ({
  pages: state.content.pages.data,
  menus: state.content.menus.data,
  locale: state.app.locale,
  games: state.games.data,
  device: state.app.device,
  jackpots: state.event.jackpots,
  sets: state.content.sets.data,
  promotions: state.content.promotions.data,
  promotionCategories: state.content.promotionCategories.data,
}))
class PromotionsContainer extends Component {
  render() {
    const {
      promotions,
      promotionCategories,
      pages,
      sets,
      match,
      games,
      device,
      jackpots,
    } = this.props;
    const page = pages.find(m => m.url === 'promotions');
    const pageSections = page?.sections || [];
    const casinoNavbar = sets
      ?.find(s => s.identifier === 'promotions-navbar')
      ?.items?.map(i => ({
        ...i,
        to: `/promotions/${i.identifier}`,
      }));

    const totalJackpot = jackpots?.reduce(
      (prev, curr) => prev + curr.Amount,
      0
    );

    const currency = (jackpots && jackpots[0]?.Currency) || 'EUR';

    if (!promotions) return null;

    const { category: categoryParam } = match.params;

    const publicPromotions = promotions.filter(p => !p.private);
    const matchedCategory =
      categoryParam &&
      promotionCategories.find(
        promotionCategory => promotionCategory.identifier === categoryParam
      );
    const promotionsByCategory =
      matchedCategory &&
      promotions.filter(
        p =>
          p.categories &&
          p.categories.find(c => c.identifier === matchedCategory.identifier)
      );

    return (
      <BasePage page={page} className="PromotionsContainer">
        <main className="container">
          <PageBuilder
            items={pageSections}
            games={games}
            totalJackpot={totalJackpot}
            currency={currency}
            device={device}
          />
          {page?.content && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: marked(page.content) }}
            />
          )}
          {casinoNavbar && (
            <CasinoCategoryMenu
              items={casinoNavbar}
              activeCategory={categoryParam}
              onSearchChange={() => null}
            />
          )}
          <div className="promotions-grid">
            {(promotionsByCategory || publicPromotions).map((p, k) => (
              <Promotion key={k} promotion={p} />
            ))}
          </div>
        </main>
      </BasePage>
    );
  }
}

export default PromotionsContainer;
