import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import marked from 'marked';
import selectJackpots from '@selectors/selectJackpots';
import MultipleVideos from '@components/MultipleVideos';
import JackpotCounter, {
  selectJackpotCounterProps,
} from '@components/JackpotCounter';
import replaceToken from '@utils/replaceToken';
import cn from 'classnames';

import './style.css';

export const selectBannersProps = state => ({
  jackpots: selectJackpots(state),
  jackpotCounterProps: selectJackpotCounterProps(state),
  user: state.player.user,
});

const SliderWithBanner = ({
  banners,
  className = '',
  height,
  jackpotCounterProps,
  user,
}) => {
  const [isPaused, setIsPaused] = useState(false);

  const bannerConfig = banners?.config;

  useEffect(() => {
    // this hack because serverside render
    // I want the slider look good on initial render when javascript still not ready yet
    // But want it fully functional when page ready
    const bannerRenderedByServer = document.querySelector(
      '.Banners.serverside'
    );

    if (bannerRenderedByServer) {
      bannerRenderedByServer.classList.remove('serverside');
    }
  });

  const onMouseDown = () => {
    // If we start to drag the slider or press any of the arrows, we want the counter to pause
    setIsPaused(true);
  };

  const onMouseUp = () => {
    // start the counter again after the slide effect is done
    setTimeout(() => {
      setIsPaused(false);
    }, 500);
  };

  const bannerStyling = {};

  if (height) {
    bannerStyling.height = `${height}px`;
  }

  const swipingEnabled = banners?.items?.length > 1;

  const autoPlaySpeed =
    typeof bannerConfig?.speed === 'number' ? bannerConfig.speed * 1000 : 5000;
  const dots = Boolean(bannerConfig?.dots && swipingEnabled);

  return (
    <div
      className="Banners serverside"
      style={bannerStyling}
      onTouchStart={swipingEnabled ? onMouseDown : undefined}
      onTouchEnd={swipingEnabled ? onMouseUp : undefined}
      onMouseDown={swipingEnabled ? onMouseDown : undefined}
      onMouseUp={swipingEnabled ? onMouseUp : undefined}>
      <Slider
        className={className + (!__CLIENT__ ? ' serverside' : '')}
        arrows={swipingEnabled}
        infinite={swipingEnabled}
        draggable={swipingEnabled}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={autoPlaySpeed}
        dots={dots}>
        {banners?.items?.map((b, k) => {
          let htmlContent = b.content && replaceToken(marked(b.content), user);

          const type = b?.config?.type;

          const videos = [b?.image, b?.video, ...(b?.videos || [])]
            .filter(el => typeof el !== 'undefined')
            .map(video => video && video.file)
            .filter(el => el.contentType.match(/^video/));

          const hasVideoAndBackground = videos?.length > 0 && b.image?.file;

          let linkOptions = null;

          if (__CLIENT__) {
            const action =
              b.action && b.action.startsWith('/') ? b.action : `/${b.action}`;
            const url = new URL(window.location.origin + (action ?? ''));

            linkOptions = {
              pathname: url.pathname,
              search: url.search,
              hash: url.hash,
              state: {
                back_url: __CLIENT__
                  ? window.routerHistory.location.pathname
                  : '/casino',
              },
            };

            if (b.action?.startsWith('#')) {
              linkOptions = {
                hash: url.hash,
              };
            }
          }

          return (
            <div
              key={k}
              className={cn('slide', {
                'enable-hover': hasVideoAndBackground,
              })}>
              {b.tag && (
                <div className="tag">
                  <span>{b.tag}</span>
                </div>
              )}

              {videos.length > 0 && (
                <MultipleVideos
                  videos={videos}
                  className="background"
                  posterUrl={b?.poster?.file?.url}
                  muted
                  loop
                  autoPlay
                  playsInline
                />
              )}

              {b.image?.file && (
                <div
                  className="background"
                  style={{
                    backgroundImage:
                      'url(' + b.image.file.url + '?fm=jpg&fl=progressive)',
                  }}
                />
              )}

              <div className="content">
                {b.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: htmlContent,
                    }}
                  />
                )}

                {type === 'jackpot' && (
                  <div className="jackpot-counter">
                    <JackpotCounter
                      isPaused={isPaused}
                      initialDurationConfig={b.config.initialDuration}
                      durationConfig={b.config.duration}
                      startPointConfig={b.config.startPoint}
                      {...jackpotCounterProps}
                    />
                  </div>
                )}

                {b.cta && b.action && (
                  <Link
                    className="button primary"
                    to={linkOptions || '/casino'}>
                    <span>{b.cta}</span>
                  </Link>
                )}
              </div>
              {b.disclaimer && (
                <div
                  className="disclaimer"
                  dangerouslySetInnerHTML={{
                    __html: marked(b.disclaimer),
                  }}
                />
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderWithBanner;
