import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouteData from '@utils/withRouteData';
import { GTM_EVENTS } from '@utils/google-tag-manager';
import { pushGtmEvent } from '@actions/app';
import marked from 'marked';
import Countdown from '@components/Countdown';
import { Icon, Translate } from 'tg-core-components';
import queryString from 'query-string';

import {
  getRelativeLeaderboard,
  getLeaderboard,
} from 'tg-core-redux/lib/modules/tournament/action';
import { tournamentOptIn } from 'tg-core-redux/lib/modules/player/action';

import Join from '@components/Tournament/Join';
import Leaderboard from '@components/Tournament/Leaderboard';
import BasePage from '@pages/BasePage';
import { withUrls } from '@utils/urls';

import './style.css';

@withRouteData
@withUrls
@connect(
  state => ({
    tournaments: state.content.tournaments.data,
    player: state.player,
    leaderboards: state.leaderboards.data,
    playerProperties: state.playerProperties.data,
  }),
  {
    getLeaderboard,
    getRelativeLeaderboard,
    tournamentOptIn,
    pushGtmEvent,
  }
)
class TournamentDetailContainer extends Component {
  state = {
    showTerms: false,
    isLoading: false,
  };

  componentDidMount() {
    const { match, tournaments } = this.props;

    const tournament = tournaments?.find(
      t => t.url === match.params.tournament
    );
    if (tournament) {
      this.getLeaderboards(tournament);
      this.setState({ showTerms: Boolean(tournament.expandedTerms) });
    }
  }

  componentDidUpdate() {
    const { match, tournaments, history } = this.props;
    const qs = queryString.parse(history.location.search);

    if (qs.pendingOptIn) {
      // Delete pendingOptIn from qs
      delete qs.pendingOptIn;
      history.replace({
        search: queryString.stringify(qs),
      });

      const tournament = tournaments?.find(
        t => t.url === match.params.tournament
      );
      if (tournament) {
        this.joinTournament();
      }
    }
  }

  getLeaderboards = async tournament => {
    this.setState({ isLoading: true });

    const { player, getLeaderboard, getRelativeLeaderboard } = this.props;
    const leaderboardRows = tournament.config?.leaderboardRows || 10;

    if (player.isAuthenticated) {
      await Promise.all([
        getLeaderboard(tournament.identifier, 0, leaderboardRows),
        getRelativeLeaderboard(tournament.identifier, player.user.Id),
      ]);
    } else {
      await getLeaderboard(tournament.identifier, 0, leaderboardRows);
    }

    this.setState({ isLoading: false });
  };

  joinTournament = async () => {
    const {
      match,
      player,
      tournamentOptIn,
      tournaments,
      history,
      pushGtmEvent,
      urls: { signInString },
    } = this.props;

    const tournament =
      tournaments && tournaments.find(t => t.url === match.params.tournament);

    if (player.isAuthenticated) {
      this.setState({ isLoading: true });

      const res = await tournamentOptIn(player.user.Id, tournament.identifier);

      if (res.type && res.type === 'TOURNAMENT_OPT_IN_FAILURE') {
        this.getLeaderboards(tournament);
        pushGtmEvent({ event: GTM_EVENTS.TOURNAMENT_JOIN_FAILURE });
      } else {
        // Delay to make sure backend is updated
        setTimeout(() => {
          this.getLeaderboards(tournament);
        }, 5000);

        pushGtmEvent({ event: GTM_EVENTS.TOURNAMENT_JOIN_SUCCESS });
      }
    } else {
      const ref_url = `?ref_url=${encodeURIComponent(
        `/tournaments/${tournament.url}?pendingOptIn=true`
      )}`;
      history.push(
        signInString({
          search: ref_url,
        })
      );
    }
  };

  render() {
    const { tournaments, match, player, leaderboards } = this.props;

    const tournament =
      tournaments && tournaments.find(t => t.url === match.params.tournament);

    if (!tournament) return <BasePage page={null} />;

    const leaderboardRows = tournament.config?.leaderboardRows || 10;

    const leaderboard = leaderboards.leaderboards[tournament.identifier] || {};
    const relativeLeaderboard =
      leaderboards.relativeLeaderboards[tournament.identifier] || {};

    const { StartDate, EndDate, Players, IsOptIn, IsEnabled } = leaderboard;

    const RelativePlayers =
      relativeLeaderboard &&
      relativeLeaderboard.Players &&
      relativeLeaderboard.Players.filter(p => p.Position !== leaderboardRows);

    const {
      banner,
      bannerContent,
      content,
      title,
      termsAndConditions,
      disclaimer,
      leaderboardHeader,
      hideLeaderboard,
    } = tournament;

    const { isLoading, showTerms } = this.state;

    const canJoin = IsOptIn && IsEnabled;
    const isJoined =
      player.user &&
      IsOptIn &&
      IsEnabled &&
      RelativePlayers &&
      RelativePlayers.find(p => p.Id === player.user.Id);

    return (
      <BasePage page={tournament} className="TournamentDetailContainer">
        <div className="container">
          <div className="tournament-banner">
            <div className="banner-content">
              <div className="text">
                {title && <div className="title">{title}</div>}

                <Countdown
                  startDate={StartDate}
                  endDate={EndDate}
                  isLoading={isLoading}
                />

                {bannerContent && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: marked(bannerContent) }}
                  />
                )}
              </div>

              <Join
                onJoinTournament={this.joinTournament}
                isAuthenticated={player.isAuthenticated}
                isLoading={isLoading}
                isJoined={isJoined}
                canJoin={canJoin}
              />
            </div>

            {banner && banner.file.url && (
              <div
                style={{
                  backgroundImage: `url("${banner.file.url}")`,
                }}
                className="banner-image"
              />
            )}
          </div>

          <div className="tournament-content">
            <div className="column">
              {content && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: marked(content) }}
                />
              )}

              {disclaimer && (
                <div
                  className="disclaimer"
                  dangerouslySetInnerHTML={{ __html: marked(disclaimer) }}
                />
              )}

              {termsAndConditions && (
                <div className="terms-and-conditions">
                  <div
                    className="terms-title"
                    onClick={() => this.setState({ showTerms: !showTerms })}>
                    <Translate
                      id="label.terms_and_conditions"
                      defaultMessage="Terms and conditions"
                    />

                    <Icon icon={showTerms ? 'angle-down' : 'angle-up'} />
                  </div>

                  {showTerms && (
                    <div
                      className="terms-content"
                      dangerouslySetInnerHTML={{
                        __html: marked(termsAndConditions),
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            {!hideLeaderboard && (
              <div className="column">
                {leaderboardHeader && (
                  <div className="leaderboard-header">{leaderboardHeader}</div>
                )}

                <Leaderboard
                  player={player}
                  playerList={Players ? Players : []}
                  isLoading={isLoading}
                  relativePlayers={RelativePlayers ? RelativePlayers : []}
                />
              </div>
            )}
          </div>
        </div>
      </BasePage>
    );
  }
}

export default TournamentDetailContainer;
