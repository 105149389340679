const selectTopGames = state => {
  const aleaccTopGames = state.event.topGames;
  const cmsGames = state.games.data;

  const popular = aleaccTopGames.popular.flatMap(addLinkedGamesIfExists);
  const wager = aleaccTopGames.wager.flatMap(addLinkedGamesIfExists);
  const wagerUWC = aleaccTopGames.popular.flatMap(addLinkedGamesIfExists);

  return {
    popular: popular
      .map(aleaccGame =>
        cmsGames.find(cmsGame => aleaccGame.GameId === cmsGame.backendId)
      )
      .filter(Boolean),
    wager: wager
      .map(aleaccGame =>
        cmsGames.find(cmsGame => aleaccGame.GameId === cmsGame.backendId)
      )
      .filter(Boolean),
    wagerUWC: wagerUWC
      .map(aleaccGame =>
        cmsGames.find(cmsGame => aleaccGame.GameId === cmsGame.backendId)
      )
      .filter(Boolean),
  };
};

const addLinkedGamesIfExists = game => {
  if (game.LinkedGames.length > 0) {
    return [game, ...game.LinkedGames];
  }
  return game;
};

export default selectTopGames;
