import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import { Input, Icon, Translate } from 'tg-core-components';
import cn from 'classnames';
import { withConfig } from '@utils/config';
import translate from 'tg-core-components/lib/lib/utils/translate';
import withInViewport from '@components/hoc/withInViewport';

import './style.css';

const CasinoCategoryMenu = ({
  items,
  activeCategory,
  searchTerm,
  onSearchChange,
  isSearchOpen,
  onToggleSearch,
  showSearch,
  config,
  inViewport,
}) => {
  const intl = useIntl();
  const [subCategory, setSubCategory] = useState('');

  const debouncedOnSearchChange = debounce(onSearchChange, 300);

  const alwaysShowSearch = config.casino.alwaysShowSearch;

  // Center the active category
  const linkRefs = useRef({});
  useEffect(() => {
    const activeCategoryRef = linkRefs.current[activeCategory];
    if (activeCategoryRef && inViewport) {
      activeCategoryRef.scrollIntoView({ block: 'nearest' });
    }
  }, [activeCategory, inViewport]);

  if (config.casino.casinoCategoryMenuStyle === 'noCategories' && showSearch) {
    return (
      <div
        className={cn('CasinoCategoryMenu', { 'is-searching': isSearchOpen })}>
        <div
          className={cn('casino-search', {
            active: isSearchOpen,
          })}>
          {((activeCategory && activeCategory !== 'overview') ||
            (searchTerm && searchTerm.length > 0)) && (
            <Link
              className="back"
              to={items?.[0]?.to || ''}
              onClick={() => {
                onToggleSearch();
                onSearchChange('');
              }}>
              <Translate
                id="action.casino.back-to-overview"
                defaultMessage="Back"
              />
            </Link>
          )}
          <Icon
            className="search-bar-icon"
            icon={`${isSearchOpen ? 'times' : 'search'}`}
            onClick={() => {
              onSearchChange('');
              onToggleSearch();
            }}
          />
          <Input
            className="search-input"
            placeholder={translate(
              {
                id: 'casino.search-placeholder',
                defaultMessage: 'Search game',
              },
              intl
            )}
            onChange={e => {
              !isSearchOpen && onToggleSearch();
              debouncedOnSearchChange(e.target.value);
            }}
            autoFocus={!alwaysShowSearch}
            onFocus={onToggleSearch}
          />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div
        className={cn('CasinoCategoryMenu', {
          'is-searching': isSearchOpen,
          'has-value': searchTerm?.length > 0,
        })}>
        {showSearch && (
          <div className={cn('casino-search', { active: isSearchOpen })}>
            <Icon
              icon={`${isSearchOpen ? 'times' : 'search'}`}
              onClick={onToggleSearch}
            />
            {(isSearchOpen || alwaysShowSearch) && (
              <Input
                className="search-input"
                placeholder={translate(
                  {
                    id: 'casino.search-placeholder',
                  },
                  intl
                )}
                value={searchTerm}
                onChange={e => debouncedOnSearchChange(e.target.value)}
                autoFocus={!alwaysShowSearch}
              />
            )}
          </div>
        )}

        <div className="categories-menu">
          {items?.map(c => {
            if (c.items && !c.config?.customCategory) {
              return (
                <Link
                  onClick={e => {
                    e.preventDefault();
                    setSubCategory(
                      subCategory === c.identifier ? '' : c.identifier
                    );
                  }}
                  to="#"
                  key={c.identifier}
                  className={cn('category-item category-with-sub-categories', {
                    expanded: subCategory === c.identifier,
                    active: c.items.some(c => c.identifier === activeCategory),
                  })}>
                  <Translate
                    id={'label.category-with-sub-categories.' + c.identifier}
                  />
                  <Icon icon="angle-down" />
                </Link>
              );
            }

            const isActive = c.identifier === activeCategory;
            const selectedImage = c.selectedImage?.file?.url;
            const unselectedImage = c.unselectedImage?.file?.url;
            const iconImage = isActive ? selectedImage : unselectedImage;

            return (
              <Link
                to={c.to}
                onClick={c.onClick}
                key={c.identifier}
                className={cn('category-item', {
                  active: isActive,
                })}
                innerRef={ref => (linkRefs.current[c.identifier] = ref)}>
                {iconImage && <img src={iconImage} alt="icon" />}
                {c.name}
              </Link>
            );
          })}
        </div>
      </div>
      {items
        ?.filter(c => c.items)
        ?.map(
          c =>
            c.identifier === subCategory && (
              <div className="SubCategoryMenu" key={c.identifier}>
                <div className="categories-menu">
                  {c.items.map(s => (
                    <Link
                      to={s.to}
                      onClick={() => setSubCategory('')}
                      key={s.identifier}
                      className={cn('category-item', {
                        active: s.identifier === activeCategory,
                      })}>
                      {s.name}
                    </Link>
                  ))}
                </div>
              </div>
            )
        )}
    </Fragment>
  );
};

export default withConfig(withInViewport(CasinoCategoryMenu));
